<template>
  <div class="news-show">
    <ShowFormItemWrapper title="タイトル" :content="title">
      <Chip
        v-if="news.attachedPdfs.length"
        label="添付書類あり"
        class="ms-2"
        role="button"
        @click="scrollToId('attached-pdf-display')"
      />
    </ShowFormItemWrapper>

    <ShowFormItemWrapper
      title="公開日時"
      :content="basicFormatter(news.publishedAt, 'withTime')"
    />
    <ShowFormItemWrapper title="地区" :content="areasToStr(news)" />

    <div class="mb-3 ql-contents">
      <Label label="本文" />
      <AutoLink>
        <div v-html="news.content"></div>
      </AutoLink>
    </div>

    <Divider class="pb-3" />

    <ShowFormItemWrapper
      v-if="news.attachedPdfs.length > 0"
      id="attached-pdf-display"
      title="添付書類"
      :content="news.attachedPdfs[0].url"
      pdf-url
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { scrollToId } from "/@/modules/ui";
import { areasToStr, newsToTitle } from "/@/modules/news";
import { Label, AutoLink } from "/@/vue/components/Atom";
import { ShowFormItemWrapper } from "/@/vue/components/Molecules/Form";
import { basicFormatter } from "/@/modules/luxon";
import { NewsClient } from "/@/types";
import Divider from "primevue/divider";
import Chip from "primevue/chip";

const props = defineProps<{
  news: NewsClient;
}>();

const title = computed(() => {
  return newsToTitle(props.news);
});
</script>

<style lang="scss">
// ! not scoped

.news-show {
  white-space: pre-wrap;

  ol,
  ul {
    padding-left: 1.5rem;
  }

  img {
    max-width: 500px;
    max-height: 400px;
  }

  .ql-contents {
    a {
      color: #1e4bb2;
      text-decoration: underline;
    }
  }
}
</style>
