<template>
  <div id="emergency-news router-view-container">
    <router-view />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.router-view-container {
  padding: 1rem;
}
</style>
