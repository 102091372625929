<template>
  <div>
    <div class="d-flex align-items-center simple-style">
      <BasicButton
        v-if="!news"
        class="text-nowrap"
        style="width: 50px; min-width: 50px"
        :class="{
          'me-2': !isMobile,
          'mb-2': !isXs && isMobile,
        }"
        label="地区"
        variant="secondary"
        outlined
        @click="$emit('update:modelValue', [...options])"
      />

      <div class="d-flex flex-wrap justify-content-center main-btns">
        <SelectButton
          :model-value="modelValue"
          :options="options.slice(0, 3)"
          :multiple="multiple"
          :class="{
            'mb-2': isMobile,
          }"
          @update:model-value="$emit('update:modelValue', $event)"
        />
        <SelectButton
          :model-value="modelValue"
          :options="options.slice(3, 6)"
          :multiple="multiple"
          :class="{
            'mb-2': isMobile,
          }"
          @update:model-value="$emit('update:modelValue', $event)"
        />
      </div>

      <BasicButton
        v-if="withReset && !news"
        label="全解除"
        variant="secondary"
        icon="pi pi-times"
        outlined
        style="width: 60px; min-width: 60px"
        :class="{
          'me-2': !isMobile,
          'mb-2': !isXs && isMobile,
        }"
        class="delete-button text-nowrap"
        @click="$emit('update:modelValue', [])"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMqUtils } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import SelectButton from "primevue/selectbutton";

defineProps<{
  modelValue: any | any[];
  options: any[];
  multiple?: boolean;
  withReset?: boolean;
  news?: boolean;
}>();

defineEmits<{
  (e: "update:modelValue", value: any | any[]): void;
}>();

const { isMobile, isXs } = useMqUtils();
</script>

<style lang="scss">
.simple-style {
  .p-button {
    border: none;
    border-radius: 0;
    color: black;
    margin: 0 0.375rem;

    &.p-button-outlined {
      border: 1px solid #e5e7eb;
    }

    &.p-highlight {
      background-color: $main-light-green;
      color: black;
    }

    @media screen and (max-width: 767px) {
      font-size: 70%;
      margin: 0 0.25rem;
    }

    @media screen and (max-width: 370px) {
      margin: 0;
    }
  }

  .main-btns {
    @media screen and (max-width: 637px) {
      width: 225px;
    }
  }

  .p-selectbutton {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
