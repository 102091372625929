<template>
  <div id="app">
    <div class="d-flex justify-content-center">
      <div class="contents w-100" style="max-width: 1100px">
        <transition name="slide" mode="out-in">
          <Header v-if="isIndexPages" :current-route="currentRouteName" />
        </transition>
          
        <div class="router-view-wrapper">
          <router-view v-slot="{ Component }" style="width: 100%">
            <transition name="slide" mode="out-in">
              <component :is="Component" :key="$route.path" />
            </transition>
          </router-view>
          <ScrollTopButton class="scroll-top a" />
        </div>

        <Footer v-if="isIndexPages" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRouterUtil } from "/@/vue/composables";
import { ScrollTopButton } from "/@/vue/components/Molecules/UI";
import { Header, Footer } from "/@/vue/components/Organisms/IndexPage";

const { currentRouteName } = useRouterUtil();

const isIndexPages = computed(() => {
  if (!currentRouteName.value) return false;

  return [
    "Index",
    "About",
    "MainActivity",
    "BranchActivity",
    "EnrollmentGuide",
    "NewsShow",
    "PostShow",
    "PostNew",
    "PrivacyPolicy",
    "NotFound",
  ].includes(currentRouteName.value);
});
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  // transform:translate(-30%)
}

#app {
  height: 100%;
  width: 100%;
  position: relative;

  .admin-btn {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  .router-view-wrapper {
    position: relative;

    > * {
      padding: 1rem;
    }

    .scroll-top {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }
  }
}
</style>
