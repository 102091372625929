<template>
  <div class="user-emergency-news-index">
    <div
      class="d-flex align-items-center justify-content-end w-100 mb-3"
      style="height: 50px"
    >
      <BasicButton
        icon="pi pi-plus"
        label="投稿一覧へ"
        class="add-btn"
        variant="secondary"
        outlined
        @click="goto({ name: 'EmergencyNewsIndex' })"
      />
    </div>

    <span v-if="news && !news.length">ゴミ箱は空です</span>
    <NewsTable
      v-else-if="news"
      :news="news"
      trashed
      @select-news="selectNews"
      @trash-news="handleDestroyNews"
      @restore-news="handleRestoreNews"
    />
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil } from "/@/vue/composables";
import { useNews } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as NewsTable } from "/@/vue/components/Organisms/Users/News";

const { goto } = useRouterUtil();

function selectNews(id: number) {
  goto({ name: "EmergencyNewsShow", params: { id: id } });
}

// api

const { getTrashedNews, destroyNews, restoreNews } = useNews();
const { data: news, mutate: getNewsMutate } = getTrashedNews();

async function handleDestroyNews(id: number | number[]) {
  if (!window.confirm("完全に削除しますか？この操作は取り消せません。")) {
    return;
  }

  if (await destroyNews(id)) {
    alert("削除しました");
    getNewsMutate();
    goto({ name: "EmergencyNewsIndex" });
  } else {
    alert("削除に失敗しました");
  }
}

async function handleRestoreNews(id: number | number[]) {
  if (!window.confirm("復元しますか？")) {
    return;
  }

  if (await restoreNews(id)) {
    alert("復元しました");
    getNewsMutate();
  } else {
    alert(
      "復元に失敗しました。時間をおいて再度お試しください。解決しない場合は開発会社にお問い合わせください。"
    );
  }
}
</script>

<style lang="scss" scoped></style>
