import Index from "./Index.vue";
import SignIn from "./SignIn.vue";
import SignUp from "./SignUp.vue";
import Home from "./Home.vue";
import NotFound from "./NotFound.vue";
import PrivacyPolicy from "./PrivacyPolicy.vue";
import { Router as NewsRouter, Root as NewsRoot } from "./News";
import { Router as PostRouter, Root as PostRoot } from "./Posts";
import { Router as UserRouter, Root as UserRoot } from "./User";

import { User } from "/@/types";

export const routes = [
  {
    path: "/",
    name: "Index",
    title: "Top",
    component: Index,
  },
  /*
  {
    path: "/sign_up",
    name: "SignUp",
    title: "新規登録",
    component: SignUp,
  },
  */
  {
    path: "/sign_in",
    name: "SignIn",
    title: "ログイン",
    component: SignIn,
  },
  /*
  {
    path: "/home",
    name: "Home",
    title: "Home",
    component: Home,
  },
  */
  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    title: "プライバシーポリシー",
    component: PrivacyPolicy,
  },
  /*
  {
    path: "/about",
    name: "About",
    title: "協会概要",
    component: () => import("./About.vue"),
  },
  {
    path: "/main_activity",
    name: "MainActivity",
    title: "県協会活動",
    component: () => import("./MainActivity.vue"),
  },
  {
    path: "/branch_activity",
    name: "BranchActivity",
    title: "支部活動",
    component: () => import("./BranchActivity.vue"),
  },
  {
    path: "/enrollment_guide",
    name: "EnrollmentGuide",
    title: "入会案内",
    component: () => import("./EnrollmentGuide.vue"),
  },
  */
  {
    path: "/news",
    title: "情報",
    component: NewsRoot,
    children: [...NewsRouter],
  },
  {
    path: "/user",
    title: "ユーザー画面",
    component: UserRoot,
    children: [...UserRouter],
  },
  {
    path: "/post",
    title: "投稿画面",
    component: PostRoot,
    children: [...PostRouter],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    title: "Not Found",
    component: NotFound,
  },
];

export const dontNeedLoginList = [
  "SignUp",
  "SignIn",
  "Index",
  "PasswordReset",
  "About",
  "MainActivity",
  "BranchActivity",
  "EnrollmentGuide",
  "PrivacyPolicy",
  "NewsShow",
  "PostShow",
  "PostNew",
];

export function isNeedLogin(name: string, user?: User): boolean {
  return !dontNeedLoginList.includes(name) && !user;
}
