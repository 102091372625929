import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const OrganizationScheme = z.object({
  name: z.string().trim().min(1),
});

export type Organization = zod.infer<typeof OrganizationScheme>;

export const OrganizationClientScheme = OrganizationScheme;
export type OrganizationClient = zod.infer<typeof OrganizationClientScheme>;
