<template>
  <div>
    <div
      class="mb-3 d-flex align-items-center justify-content-end"
      style="height: 50px"
    >
      <div v-if="news?.trashed">
        <Chip
          label="ゴミ箱の記事"
          class="ms-3"
          style="background-color: #ef4444; color: white"
        />
      </div>
    </div>

    <div v-if="news">
      <NewsForm
        v-model:title="form.title"
        v-model:content="form.content"
        v-model:published-at="form.publishedAt"
        v-model:label="form.label"
        v-model:area="form.area"
        v-model:areas="form.areas"
        v-model:new-attached-pdfs="form.newAttachedPdfs"
        :attached-pdfs="news.attachedPdfs"
        is-emergency
        class="pb-5"
        :user="user"
        :errors="errors"
      />

      <div class="d-flex justify-content-center my-4">
        <BasicButton
          icon="pi pi-chevron-circle-left"
          label="キャンセル"
          variant="secondary"
          class="me-5"
          outlined
          @click="backto"
        />
        <BasicButton
          icon="pi pi-refresh"
          label="更新"
          button-type="submit"
          @click="submit"
        />
      </div>
    </div>
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import {
  useZodScheme,
  useNews,
  useRouterUtil,
  useUser,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as NewsForm } from "/@/vue/components/Organisms/Users/News";
import {
  NewsCheckScheme,
  NewsForm as NewsFormType,
  NewsClient,
} from "/@/types/news.d";
import Chip from "primevue/chip";

const { getUserInfo } = useUser();
const { data: user } = getUserInfo();

const { getNewsItem, updateNews } = useNews();

const { currentRouteParams, backto, goto } = useRouterUtil();
const id = computed(() => Number(currentRouteParams.value.id));

const { data: news } = getNewsItem(id, { admin: true });

watch(news, (n) => {
  if (!form.id && n) {
    resetForm(n);
  }
});

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<NewsFormType>(
  NewsCheckScheme,
  news.value
);

watch(form, (f) => {
  if (f.title && f.title.length > 20) {
    form.title = f.title.slice(0, 20);
  }
});

function resetForm(news?: NewsClient) {
  form.id = news?.id || undefined;
  form.title = news?.title || undefined;
  form.content = news?.content || undefined;
  form.heldAt = news?.heldAt || undefined;
  form.place = news?.place || undefined;
  form.lecturer = news?.lecturer || undefined;
  form.cost = news?.cost || undefined;
  form.area = news?.area || undefined;
  form.areas = news?.areas || [];
  form.applicationMethod = news?.applicationMethod || undefined;
  form.publishedAt = news?.publishedAt || undefined;
  form.label = news?.label || "announce_from_prefecture_association";
  form.showTop = news?.showTop || false;
  form.newAttachedPdfs = news?.attachedPdfs.map((pdf) => pdf.signedId) || [];
}

onMounted(() => {
  startValidation.value = true;
  if (news.value) {
    resetForm(news.value);
  }
});

async function submit() {
  try {
    startValidation.value = true;

    const args = NewsCheckScheme.parse(form);

    if (await updateNews(id.value, args)) {
      alert("更新しました");
      goto({ name: "EmergencyNewsIndex" });
    } else {
      alert(
        "更新に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。");
    console.error(e);
  }
}
</script>

<style scoped></style>
