export function setStorage(key: string, val: any) {
  localStorage.setItem(key, JSON.stringify(val));
}

export function getStorage(key: string) {
  const val = localStorage.getItem(key);

  if (!val || val === "undefined") return undefined;

  return JSON.parse(val as string);
}

export function clearStorage() {
  localStorage.clear();
}
