import { computed } from "vue";
import { RouteLocationRaw, useRoute, useRouter } from "vue-router";

export function useRouterUtil() {
  const router = useRouter();
  const route = useRoute();

  const currentRoute = computed(() => {
    return router.currentRoute.value;
  });

  const currentRouteParams = computed(() => {
    return router.currentRoute.value.params;
  });

  const currentRouteName = computed(() => {
    return router.currentRoute.value.name?.toString();
  });

  const currentRouteMeta = computed(() => {
    return router.currentRoute.value.meta;
  });

  const matchedRoutes = computed(() => {
    return route.matched;
  });

  // goto

  function goto(to: RouteLocationRaw) {
    router.push(to);
  }

  function backto(options = { confirm: true }) {
    const { confirm } = options;

    if (confirm) {
      if (
        !window.confirm(
          "前のページに戻る場合、この操作により現在入力した内容が失なわれます。前のページに戻る場合は「OK」を押して下さい。"
        )
      ) {
        return;
      }
    }

    if (window.history.length > 1) {
      router.go(-1);
    } else {
      router.push("/");
    }
  }

  return {
    currentRoute,
    currentRouteName,
    currentRouteParams,
    currentRouteMeta,

    matchedRoutes,

    goto,
    backto,
  };
}
