import zod from "zod";
import { z } from "/@/modules/zodUtils";

// お知らせ, 公開講座, 研修会, 緊急情報
export const newsLabels = [
  "announce_from_prefecture_association", // 県協会
  "current_activity_prefecture_association",
  "caution",
  "information",
  "other",
  "announce_from_prefecture",
  "announce_from_city",
  "announce_from_country",
] as const;
export const NewsLabelScheme = z.enum(newsLabels);
export type NewsLabel = zod.infer<typeof NewsLabelScheme>;

// ID(key), 組織ID(number)・タイトル(string)・公開日時(datetime)・開催日時(datetime)・場所(string)・概要(text)・講師(text)・費用(text)・申し込み方法(text)・申し込み用 google form リンク (string)・ラベル(enum)・TOPに掲載するかどうか(boolean)

export const newsAreas = [
  "能登北部",
  "能登中部",
  "かほく",
  "金沢",
  "白山・野々市",
  "南加賀",
];

export const NewsLabelTitleScheme = z.object({
  label: NewsLabelScheme,
  title: z.string().trim().min(0).max(20).optional().nullable(),
});

export const NewsLabelTitleWithRefineScheme = NewsLabelTitleScheme.refine(
  ({ label, title }) => {
    return (label != "information" && label != "other") || !!title;
  },
  {
    path: ["title"],
  }
);

const newsRestObj = {
  id: z.number().optional(),
  publishedAt: z.string().optional().nullable(),
  //heldAt: z.string().optional(),
  //place: z.string().trim().min(0).optional(),
  content: z.string().trim().min(1, { message: "必須" }).max(16777215),
  //lecturer: z.string().trim().min(1).optional(),
  //cost: z.string().trim().min(0).optional(),
  area: z.string().trim().min(0).optional(),
  areas: z.string().array().min(0),
  //applicationMethod: z.string().trim().min(0).optional(),
  //applicationFormLink: z.string().trim().min(0).optional(),
  showTop: z.boolean(),
  newAttachedPdfs: z.string().array(),
};

export const NewsScheme = NewsLabelTitleScheme.extend(newsRestObj);

export type News = zod.infer<typeof NewsScheme>;

export const NewsCheckScheme = z.intersection(
  NewsLabelTitleWithRefineScheme,
  z.object(newsRestObj)
);

export const NewsFormScheme = NewsScheme.partial().extend({
  label: NewsLabelScheme,
  areas: z.string().array().min(0),
  newAttachedPdfs: z.string().array(),
});
export type NewsForm = zod.infer<typeof NewsFormScheme>;
export const NewsClientScheme = NewsScheme.extend({
  id: z.number(),
  organizationId: z.number(),
  trashed: z.boolean(),
  publishedAt: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  attachedPdfs: z
    .object({
      url: z.string(),
      signedId: z.string(),
    })
    .array(),
});
export type NewsClient = zod.infer<typeof NewsClientScheme>;
