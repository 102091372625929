<template>
  <Card
    role="button"
    style="height: 400px; overflow-y: scroll"
    @click="$emit('gotoNewsShow', news.id)"
  >
    <template #title>
      <div class="text-truncate">
        <span>
          {{ newsToTitle(news) }}
        </span>
      </div>
    </template>
    <template #subtitle>
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <span class="date-text">{{
          basicFormatter(news.publishedAt, "withTime")
        }}</span>
        <!-- i v-if="news.attachedPdfs.length" class="pi pi-paperclip"></i -->
        <Chip v-if="news.attachedPdfs.length" label="添付書類あり" />
      </div>
    </template>
    <template #content>
      <div class="mb-2">
        <span class="text-nowrap">地区：</span>
        <span>{{ targetAreas }}</span>
      </div>
      <AutoLink>
        <span class="show-white-space ql-content" v-html="news.content"></span>
      </AutoLink>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { areasToStr, newsToTitle } from "/@/modules/news";
import { AutoLink } from "/@/vue/components/Atom";
import { NewsClient } from "/@/types/news.d";
import Card from "primevue/card";
import Chip from "primevue/chip";

const props = defineProps<{
  news: NewsClient;
}>();

defineEmits<{
  (e: "gotoNewsShow", id: number): void;
}>();

const targetAreas = computed(() => {
  return areasToStr(props.news);
});
</script>

<style lang="scss">
.ql-content {
  a {
    color: #1e4bb2;
    text-decoration: underline;
  }
}
</style>
