<template>
  <div class="user-emergency-news-index">
    <div
      class="d-flex align-items-center justify-content-end w-100 mb-3"
      style="height: 50px"
    >
      <div class="d-flex align-items-center">
        <BasicButton
          icon="pi pi-plus"
          label="新規作成"
          class="add-btn me-2"
          variant="secondary"
          outlined
          @click="goto({ name: 'EmergencyNewsNew' })"
        />
        <BasicButton
          icon="pi pi-trash"
          label="ゴミ箱"
          variant="danger"
          outlined
          @click="goto({ name: 'EmergencyNewsTrashIndex' })"
        />
      </div>
    </div>

    <NewsTable
      v-if="news"
      :news="news"
      @select-news="selectNews"
      @trash-news="handleTrashNews"
    />
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil } from "/@/vue/composables";
import { useNews } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as NewsTable } from "/@/vue/components/Organisms/Users/News";

const { goto } = useRouterUtil();

function selectNews(id: number) {
  goto({ name: "EmergencyNewsShow", params: { id: id } });
}

// api

const { getNews, trashNews } = useNews();
const { data: news, mutate: getNewsMutate } = getNews({
  all: true,
});

const goTrashMessage =
  "ゴミ箱に移動しますか？ ゴミ箱の記事は一定期間後に完全に削除されます。";

async function handleTrashNews(id: number | number[]) {
  if (!window.confirm(goTrashMessage)) {
    return;
  }

  if (await trashNews(id)) {
    alert("ゴミ箱に移動しました");
    getNewsMutate();
    goto({ name: "EmergencyNewsIndex" });
  } else {
    alert("移動に失敗しました");
  }
}
</script>

<style lang="scss" scoped></style>
