<template>
  <div>
    <span v-if="total > 0">{{ currentPage }}/{{ totalPages }}page</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  perPage: number;
  total: number;
  first: number;
}>();

const totalPages = computed(() => {
  return Math.ceil(props.total / props.perPage);
});

const currentPage = computed(() => {
  return Math.ceil(props.first / props.perPage) + 1;
});
</script>

<style scoped></style>
