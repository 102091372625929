<template>
  <div>
    <template v-if="post">
      <AdminPostShow
        :post="post"
        is-admin
        class="mb-3"
        @destroy-reply="hundleDestroyReply"
      />
      <AddPostReply
        v-if="addReplyMode"
        :post="post"
        @cancel="addReplyMode = false"
        @submit="hundleSubmitReply"
      />
    </template>

    <div
      v-if="!addReplyMode"
      class="d-flex flex-wrap justify-content-center my-5"
    >
      <BasicButton
        label="戻る"
        variant="secondary"
        class="me-4"
        outlined
        @click="backto({ confirm: false })"
      />
      <BasicButton
        label="返信"
        variant="primary"
        outlined
        @click="startAddReply"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { scrollToBottom } from "/@/modules/ui";
import { usePosts, useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { AdminShow as AdminPostShow } from "/@/vue/components/Molecules/Post";
import { AddReply as AddPostReply } from "/@/vue/components/Organisms/Posts";
import { PostReply } from "/@/types";

const { getPostsItem } = usePosts();
const { currentRouteParams, backto } = useRouterUtil();

const id = computed(() => {
  return Number(currentRouteParams.value.id as string);
});

const { data: post, mutate: getPostsItemMutate } = getPostsItem(id, {
  admin: true,
});

// reply

const addReplyMode = ref(false);

function startAddReply() {
  addReplyMode.value = true;
  scrollToBottom();
}

const { createReply, destroyReply } = usePosts();

async function hundleSubmitReply(reply: PostReply) {
  if (!post.value) return;

  if (await createReply(post.value.id, reply)) {
    addReplyMode.value = false;
    getPostsItemMutate();
    alert("返信しました。");
  } else {
    alert("返信に失敗しました。");
  }
}

async function hundleDestroyReply(id: number) {
  if (!window.confirm("返信を削除します。よろしいですか？")) {
    return;
  }

  if (await destroyReply(id)) {
    getPostsItemMutate();
  } else {
    alert("返信の削除に失敗しました。");
  }
}
</script>

<style scoped></style>
