<template>
  <div>
    <NewsShow v-if="news" :news="news" />
    <span v-if="error" class="text-danger">ページがみつかりません</span>
    <div class="d-flex flex-wrap justify-content-center">
      <BasicButton
        label="戻る"
        icon="pi pi-arrow-left"
        variant="secondary"
        outlined
        @click="backto({ confirm: false })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useNews, useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Show as NewsShow } from "/@/vue/components/Molecules/News";

const { getNewsItem } = useNews();
const { currentRouteParams, backto } = useRouterUtil();

const id = computed(() => {
  return Number(currentRouteParams.value.id as string);
});

const { data: news, error } = getNewsItem(id);
</script>

<style></style>
