import { fromISO, fromObject, isoNow, setup } from "./luxon";
import { LuxonTimeObject } from "./luxon";

setup();

export type PickerTimeObject = {
  hours: number;
  minutes: number;
};

export function luxonToPickerObject(
  obj: LuxonTimeObject
): PickerTimeObject | undefined {
  /* 
    luxon object -> vue3datetimepicker object for timepicker.
    change object keys hour -> hours, minute -> minutes.
  */

  if (!obj || !obj.hour || (!obj.minute && obj.minute != 0)) {
    return undefined;
  }

  const nObj: PickerTimeObject = {
    hours: obj.hour,
    minutes: obj.minute,
  };

  return nObj;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("luxonToPickerObject", () => {
    expect(luxonToPickerObject({ hour: 10, minute: 0 })).toStrictEqual({
      hours: 10,
      minutes: 0,
    });
  });
}

export function pickerToLuxonObject(
  obj: PickerTimeObject | undefined,
  env: LuxonTimeObject = { year: 2000, month: 1, day: 1 }
): LuxonTimeObject | undefined {
  if (!obj || !obj.hours || (!obj.minutes && obj.minutes != 0)) {
    return undefined;
  }

  const nObj: LuxonTimeObject = {
    hour: obj.hours,
    minute: obj.minutes,
  };

  if (env) {
    nObj.year = env.year;
    nObj.month = env.month;
    nObj.day = env.day;
  }

  return nObj;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("pickerToLuxonObject", () => {
    expect(
      fromObject(pickerToLuxonObject({ hours: 10, minutes: 0 }))?.toISO()
    ).toBe("2000-01-01T10:00:00.000+09:00");
    expect(
      fromObject(
        pickerToLuxonObject(
          { hours: 10, minutes: 0 },
          { year: 2022, month: 2, day: 22 }
        )
      )?.toISO()
    ).toBe("2022-02-22T10:00:00.000+09:00");
  });
}

export function timeObjToAt(
  timeObj: PickerTimeObject | undefined,
  lTimeObj?: LuxonTimeObject
) {
  const lObj = pickerToLuxonObject(timeObj, lTimeObj);

  if (!lObj) {
    return undefined;
  }

  return fromObject(lObj)?.toISO();
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("timeObjToAt", () => {
    expect(timeObjToAt({ hours: 10, minutes: 0 })).toBe(
      "2000-01-01T10:00:00.000+09:00"
    );
  });
}

export function atToTimeObj(
  at: string | undefined
): PickerTimeObject | undefined {
  if (!at) {
    return undefined;
  }

  const m = fromISO(at);

  if (!m) {
    return undefined;
  }

  return {
    hours: m.hour,
    minutes: m.minute,
  };
}

export function createStartTime(
  iso: string | Date | undefined
): PickerTimeObject | undefined {
  let tiso: string | undefined;
  if (iso instanceof Date) {
    tiso = iso.toISOString();
  } else {
    tiso = iso;
  }

  const inputOrCurrent = fromISO(tiso ? tiso : isoNow());

  if (!inputOrCurrent) {
    throw new Error("予期せぬエラーが発生しました");
  }

  const inputValue: LuxonTimeObject = inputOrCurrent
    .set({
      minute: 0,
    })
    .toObject();

  return luxonToPickerObject(inputValue);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const n = "2000-01-01T10:00:00.000+09:00";

  const timeObj: LuxonTimeObject = {
    year: 2000,
    month: 3,
    day: 25,
    hour: 10,
    minute: 0,
  };

  const at = fromObject(timeObj)?.toISO();

  if (at) {
    it("createStartTime", () => {
      expect(createStartTime(n)).toStrictEqual({
        hours: 10,
        minutes: 0,
      });
    });
  }
}
