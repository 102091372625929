<template>
  <div class="px-3 pb-3">
    <div class="grad-top">
      <div
        class="mt-3"
        style="font-size: 100%"
        :class="{
          'd-flex justify-content-center': isMobile,
        }"
      >
        <b>石川県介護支援専門員協会</b>
      </div>
      <div
        class="d-flex align-items-top flex-wrap"
        style="font-size: 75%"
        :class="{ 'justify-content-center': isMobile }"
      >
        <p>【事務局】</p>
        <span :style="isMobile ? { width: '70px' } : { width: '80px' }">
          〒920-8557
        </span>
        <span v-if="isMobile">石川県金沢市本多町3-1-10</span>

        <div
          class="d-flex flex-column"
          :class="{ 'justify-contet-center': isMobile }"
        >
          <span v-if="!isMobile">石川県金沢市本多町3-1-10</span>
          <span>社会福祉法人 石川県社会福祉協議会・施設振興課</span>
        </div>
      </div>
      <div
        class="d-flex align-items-center flex-wrap"
        style="font-size: 75%"
        :class="{ 'justify-content-center mb-3': isMobile }"
      >
        <span v-if="!isMobile">　　　　　</span>
        <span>FAX：076-208-5760</span>
      </div>
    </div>

    <div
      class="d-flex flex-column align-items-center justify-content-center my-2"
      style="font-size: 75%"
    >
      <div
        style="
          font-size: 75%;
          border-left: solid 1px black;
          border-right: solid 1px black;
        "
        class="px-2 mb-2"
      >
        <router-link :to="{ name: 'PrivacyPolicy' }">
          プライバシーポリシー
        </router-link>
      </div>

      <span style="font-size: 75%">
        Copyright ©︎{{ now.year }}, Ishikawa Care Manager Association. All
        rights reserved.
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMqUtils } from "/@/vue/composables";
import { luxonNow } from "/@/modules/luxon";

const { isMobile } = useMqUtils();

const now = luxonNow();
</script>

<style scoped>
.grad-top::before {
  content: "";
  display: block;
  height: 10px;
  width: 100%;
  background: linear-gradient(
    to left,
    #f08f8d40 0%,
    #b6eea640 25%,
    #92c6db40 50%,
    #d6b2f040 75%,
    #c3768940 100%
  );
}
</style>
