<template>
  <div>
    <div v-if="selectedItems.length" class="table-action-menu-button-wrapper">
      <MenuButton :menu-items="menuItems" />
    </div>
    <DataTable
      v-model:selection="selectedItems"
      :value="posts"
      striped-rows
      paginator
      :rows="10"
      show-gridlines
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-click="$emit('selectPost', $event.data.id)"
    >
      <Column
        v-if="!isMobile"
        selection-mode="multiple"
        header-style="width: 3rem"
      >
      </Column>
      <Column
        sortable
        field="label"
        header="タイトル"
        :style="isMobile ? {} : { width: '250px', 'min-width': '250px' }"
      >
        <template #body="{ data }">
          <span>{{ postToTitle(data) }}</span>
        </template>
      </Column>
      <Column
        sortable
        field="createdAt"
        header="投稿日時"
        :style="
          isMobile
            ? { width: '150px', 'min-width': '150px' }
            : { width: '150px', 'min-width': '150px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.createdAt, "slashStyle") }}</span>
        </template>
      </Column>
      <Column
        v-if="!isMobile"
        sortable
        field="industry"
        header="業種"
        style="width: 100px; max-width: 100px"
      >
        <template #body="{ data }">
          <span>{{ postIndustryTranslater(data.industry) }}</span>
        </template>
      </Column>

      <Column
        v-if="!isMobile"
        filed="content"
        header="本文"
        style="min-width: 150px"
      >
        <template #body="{ data }">
          <span>{{ stripHtml(data.content.slice(0, 50)) }}</span>
        </template>
      </Column>

      <Column
        :style="
          trashed
            ? { width: '90px', 'min-width': '90px' }
            : { width: '60px', 'min-width': '60px' }
        "
      >
        <template #body="{ data }">
          <div class="d-flex">
            <BasicButton
              v-if="trashed"
              variant="primary"
              icon="pi pi-refresh"
              need-icon
              text
              button-type="submit"
              class="me-2"
              @click="$emit('restorePost', data.id)"
            />
            <BasicButton
              icon="pi pi-trash"
              need-icon
              variant="danger"
              text
              button-type="submit"
              @click="$emit('trashPost', data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { postIndustryTranslater, postToTitle } from "/@/modules/post";
import { stripHtml } from "/@/modules/string";
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules";
import { PostClient } from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { MenuItem } from "primevue/menuitem";

const props = defineProps<{
  posts: PostClient[];
  trashed?: boolean;
}>();

const emit = defineEmits<{
  (e: "selectPost", id: number): void;
  (e: "trashPost", id: number | number[]): void;
  (e: "restorePost", id: number | number[]): void;
}>();

const { isMobile } = useMqUtils();

const selectedItems = ref<PostClient[]>([]);

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "復元する",
      icon: "pi pi-refresh",
      visible: props.trashed,
      command: () => {
        emit(
          "restorePost",
          selectedItems.value.map((n) => n.id)
        );
        selectedItems.value = [];
      },
    },
    {
      label: props.trashed ? "完全に削除する" : "ゴミ箱へ移動",
      icon: "pi pi-trash",
      command: () => {
        emit(
          "trashPost",
          selectedItems.value.map((n) => n.id)
        );
        selectedItems.value = [];
      },
    },
  ];
});
</script>

<style scoped></style>
