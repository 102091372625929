export default [
  {
    path: "",
    name: "PostNew",
    component: () => import("./New.vue"),
  },
  {
    path: "show/:id",
    name: "PostShow",
    component: () => import("./Show.vue"),
  },
];
