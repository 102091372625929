<template>
  <textarea
    :class="formClass"
    :value="value"
    :placeholder="placeholder"
    :rows="rows?.toString() || 4"
    @input="update"
  ></textarea>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  value?: string;
  isValid?: boolean;
  formId?: string;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<{ (e: "update:value", value: string): void }>();

const formClass = computed(() => {
  return {
    "form-control": true,
    "is-invalid": !props.isValid,
  };
});

function update(e: Event) {
  if (e.target instanceof HTMLTextAreaElement) {
    emit("update:value", e.target.value);
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}
</script>

<style lang="scss" scoped>
textarea {
  height: 100px;
}
</style>
