<template>
  <div>
    <div class="p-3 pb-2">
      <div class="w-100 grad-bottom">
        <img
          :src="Logo"
          alt="石川県介護支援専門員協会"
          class="mb-4"
          role="button"
          :style="
            isMobile
              ? {
                  width: '200px',
                }
              : {
                  width: '300px',
                }
          "
          @click="goto({ name: 'Index' })"
        />
      </div>

      <div v-if="currentRoute === 'Index'" class="w-100 grad-bottom">
        <div
          class="d-flex flex-column align-items-center py-4"
          style="padding-bottom: 1.1rem !important"
        >
          <h2
            class="mb-2 d-flex flex-wrap justify-content-center"
            :style="
              isMobile ? { 'font-size': '1.75rem' } : { 'font-size': '2.1rem' }
            "
          >
            <b class="text-nowrap">能登半島</b>
            <b class="text-nowrap">災害対策ポータル</b>
          </h2>
          <span
            class="d-flex flex-wrap justify-content-center"
            :style="
              isMobile
                ? {
                    'font-size': '75%',
                  }
                : {
                    'font-size': '100%',
                  }
            "
          >
            <span class="text-nowrap">一人ひとりが情報を提供し、</span>
            <span class="text-nowrap">
              すべての人の暮らしを守る為のポータルサイト
            </span>
          </span>
        </div>
      </div>

      <div v-if="false" class="d-flex flex-row">
        <template v-for="menuItem in menuItems" :key="menuItem.name">
          <BasicButton
            v-if="!menuItem.hidden"
            class="me-2 d-flex align-items-center"
            :icon="menuItem.icon"
            :label="menuItem.label"
            @click="redirectTo(menuItem.name)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouterUtil, useMqUtils } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import Logo from "../../../../../../public/img/unnamed.gif";

defineProps<{
  currentRoute?: string;
}>();

const { isMobile } = useMqUtils();
const { goto } = useRouterUtil();

const menuItems = computed(() => {
  return [
    {
      name: "Index",
      label: "ホーム",
      icon: "pi pi-home",
      hidden: true,
    },
    {
      name: "About",
      label: "協会概要",
      icon: "pi pi-info-circle",
      hidden: true,
    },
    {
      name: "MainActivity",
      label: "県協会活動",
      icon: "pi pi-th-large",
      hidden: true,
    },
    {
      name: "BranchActivity",
      label: "支部活動",
      icon: "pi pi-th-large",
      hidden: true,
    },
    {
      name: "EnrollmentGuide",
      label: "入会案内",
      icon: "pi pi-th-large",
      hidden: true,
    },
  ];
});

function redirectTo(name: string) {
  goto({ name });
}
</script>

<style scoped lang="scss">
.grad-bottom::after {
  content: "";
  display: block;
  height: 10px;
  background: linear-gradient(
    to left,
    #f08f8d40 0%,
    #b6eea640 25%,
    #92c6db40 50%,
    #d6b2f040 75%,
    #c3768940 100%
  );
}
</style>
