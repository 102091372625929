import Index from "./Index.vue";
import Show from "./Show.vue";
import Trash from "./Trash.vue";

export default [
  {
    path: "",
    name: "UserPostsIndex",
    component: Index,
    meta: {
      title: "ユーザー投稿管理",
    },
  },
  {
    path: ":id",
    name: "UserPostShow",
    component: Show,
    meta: {
      title: "詳細",
      tree: [
        {
          title: "ユーザー投稿管理",
          name: "UserPostsIndex",
        },
      ],
    },
  },
  {
    path: "trash",
    name: "UserPostsTrashIndex",
    component: Trash,
    meta: {
      title: "ゴミ箱",
      tree: [
        {
          title: "ユーザー投稿管理",
          name: "UserPostsIndex",
        },
      ],
    },
  },
];
