<template>
  <div>
    <div class="px-3">
      <div class="mb-3">
        <h5><b>プライバシーポリシー</b></h5>
      </div>

      <div style="font-size: 90%">
        <div class="mb-3">
          石川県介護支援専門員協会（以下、「当協会」という。）は、ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。
        </div>

        <div class="mb-3">
          <p>1．事業者情報</p>
          <p>組織名：石川県介護支援専門員協会</p>
          <p>所在地：石川県金沢市本多町3-1-10</p>
        </div>

        <div class="mb-3">
          <p>2．個人情報の取得方法</p>
          <p>
            当協会は、当協会のサービスにおいてユーザーが利用するとき、氏名・電話番号・メールアドレスなど個人を特定できる情報を取得する可能性があります。
          </p>
        </div>

        <div class="mb-3">
          <p>3．個人情報の利用目的</p>
          <ul>
            <li>
              取得した閲覧・購買履歴等の情報を分析し、事業においてユーザーに適した新商品・サービスをお知らせするために利用します。また、取得した閲覧・購買履歴等の情報は、結果をスコア化した上で当該スコアを第三者へ提供します
            </li>
            <li>
              ユーザーが利用しているサービスの新機能や更新情報、キャンペーン情報などをメール送付によりご案内するため
            </li>
            <li>
              ユーザーが利用しているサービスのメンテナンスなど、必要に応じたご連絡をするため
            </li>
            <li>ユーザーからのコメントやお問い合わせに回答するため</li>
            <li>
              利用規約に違反したユーザーの特定、その他不正不当な目的でサービスを利用したユーザーの特定をし、ご利用をお断りするため
            </li>
            <li>
              有料プランを利用しているユーザーに対して利用料金をご請求するため
            </li>
          </ul>
          <p>
            個人情報の利用目的は、変更前後の関連性について合理性が認められる場合に限って変更するものとします。
          </p>
          <p>
            個人情報の利用目的について変更を行った際は、変更後の目的について当協会所定の方法によってユーザーに通知し、加えてWebサイト上にも公表するものとします。
          </p>
        </div>

        <div class="mb-3">
          <p>
            4．個人データの共同利用 当協会は、以下のとおり共同利用を行います。
          </p>
          <ul>
            <li>個人データの管理に関する責任者</li>
            <li class="without-dot">株式会社FRYTH</li>
            <li>共同して利用する者の利用目的</li>
            <li class="without-dot">上記「利用目的」の内容と同様。</li>
            <li>利用項目</li>
            <li class="without-dot">氏名、電話番号、メールアドレス</li>
            <li>共同して利用する者の範囲</li>
            <li class="without-dot">当社関連会社</li>
          </ul>
        </div>

        <div class="mb-3">
          <p>5．個人データの第三者提供について</p>
          <p>
            当協会は以下の場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。
          </p>
          <ul>
            <li>法令に基づく場合</li>
            <li>
              人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
            </li>
            <li>
              公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
            </li>
            <li>
              国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </li>
            <li>
              次に掲げる事項をあらかじめ本人に通知または公表し、かつ当協会が個人情報保護委員会に届出をしたとき
            </li>
            <ol>
              <li>第三者への提供を利用目的とすること</li>
              <li>第三者に提供される個人データの項目</li>
              <li>第三者への提供の方法</li>
              <li>
                本人の求めに応じて当該個人情報の第三者への提供を停止すること
              </li>
              <li>本人の求めを受け付ける方法</li>
            </ol>
          </ul>
        </div>

        <div class="mb-3">
          <p>6．匿名加工情報に関する取扱い</p>
          <p>
            当協会は、匿名加工情報（特定の個人を識別できないよう加工した個人情報であって、復元ができないようにしたもの）を作成する場合、以下の対応を行います。
          </p>
          <ul>
            <li>法令で定める基準に従い適正な加工を施す</li>
            <li>法令で定める基準に従い安全管理措置を講じる</li>
            <li>匿名加工情報に含まれる個人に関する情報の項目を公表する</li>
            <li>
              作成元となった個人情報の本人を識別するため、他の情報と照合すること
            </li>
          </ul>
        </div>

        <div class="mb-3">
          <p>7．保有個人データの開示、訂正</p>
          <p>
            当協会は、本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は、以下の手続きに従ってご請求ください。
          </p>
          <p>以下の書類等をお問い合わせ窓口宛てにご郵送願います。</p>
          <ul>
            <li>保有個人データ開示等請求書</li>
            <li>
              本人確認書類の写し（運転免許証、個人番号カード、在留カード、特別永住者証明書、パスポートなどの顔写真付き証明書）
            </li>
            <li>手数料（事務手数料として1請求につき500円）</li>
            <li>送付先住所</li>
            <li class="without-dot">〒920-8557</li>
            <li class="without-dot">石川県金沢市本多町3-1-10</li>
            <li class="without-dot">石川県介護支援専門員協会</li>
          </ul>
        </div>

        <div class="mb-3">
          <p>8．個人情報取扱いに関する相談や苦情の連絡先</p>
          <p>
            当協会の個人情報の取扱いに関するご質問やご不明点、苦情、その他のお問い合わせはお電話にてご連絡ください。
          </p>
        </div>

        <div class="mb-3">
          <p>9．SSL（Secure Socket Layer）について</p>
          <p>
            当協会のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
          </p>
        </div>

        <div class="mb-3">
          <p>10．cookieについて</p>
          <p>
            cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。当社Webサイトがcookieとして送るファイルは、個人を特定するような情報を含んでおりません。
          </p>
          <p>
            お使いのWebブラウザの設定により、cookieを無効にすることも可能です。
          </p>
        </div>

        <div class="mb-3">
          <p>11．プライバシーポリシーの制定日及び改定日</p>
          <p>制定：2024年1月6日</p>
        </div>

        <div class="mb-3">
          <p>12．免責事項</p>
          <ul>
            <li>
              当協会Webサイトに掲載されている情報の正確さには万全を期していますが、利用者が当協会Webサイトの情報を用いて行う一切の行為に関して、当協会は一切の責任を負わないものとします。
            </li>
            <li>
              当協会は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
            </li>
          </ul>
        </div>

        <div class="mb-3">
          <p>13．著作権・肖像権</p>
          <p>
            当協会Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されており、無断での使用や転用は禁止されています。
          </p>
        </div>

        <div class="mb-3">
          <p>14．リンク</p>
          <p>
            当協会Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンク設置をお断りすることがあります。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
ul,
ol {
  padding-left: 1.5em;
}

.without-dot {
  list-style: none;
}
</style>
