import { Router as NewsRouter, Root as NewsRoot } from "./News";
import { Router as PostRouter, Root as PostRoot } from "./Posts";
import {
  Router as EmergencyNewsRouter,
  Root as EmergencyNewsRooot,
} from "./EmergencyNews";

export default [
  {
    path: "",
    name: "UserIndex",
    component: () => import("./Index.vue"),
    meta: {
      title: "管理トップ",
      tree: [],
    },
  },
  {
    path: "activity",
    name: "UserActivity",
    component: () => import("./Activity.vue"),
  },
  {
    path: "branch",
    name: "UserBranch",
    component: () => import("./Branch.vue"),
  },
  /*
  {
    path: "news",
    component: NewsRoot,
    children: [...NewsRouter],
  },
  */
  {
    path: "posts",
    component: PostRoot,
    children: [...PostRouter],
  },
  {
    path: "emergency_news",
    title: "緊急情報",
    component: EmergencyNewsRooot,
    children: [...EmergencyNewsRouter],
  },
];
