<template>
  <div>
    <div
      class="mb-3 d-flex align-items-center justify-content-end w-100"
      style="height: 50px"
    >
      <BasicButton
        label="編集"
        variant="secondary"
        outlined
        @click="goto({ name: 'EmergencyNewsEdit', params: { id: id } })"
      />
    </div>

    <NewsShow v-if="news" :news="news" />

    <div class="d-flex flex-wrap justify-content-center my-5">
      <BasicButton
        label="戻る"
        icon="pi pi-arrow-left"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto({ confirm: false })"
      />
      <BasicButton
        label="編集"
        variant="secondary"
        outlined
        @click="goto({ name: 'EmergencyNewsEdit', params: { id: id } })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useNews, useRouterUtil } from "/@/vue/composables";
import { Show as NewsShow } from "/@/vue/components/Molecules/News";
import { BasicButton } from "/@/vue/components/Atom";

const { getNewsItem } = useNews();
const { currentRouteParams, goto, backto } = useRouterUtil();

const id = computed(() => {
  return Number(currentRouteParams.value.id as string);
});

const { data: news } = getNewsItem(id, { admin: true });
</script>

<style scoped></style>
