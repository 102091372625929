import {
  PostClient,
  PostIndustry,
  PostLabel,
  PostRole,
  PostTerm,
} from "/@/types/post.d";

export function postLabelTranslater(postLabel: PostLabel) {
  switch (postLabel) {
    case "lack_supplies":
      return "物品の不足";
    case "lack_manpower":
      return "人員の不足";
    case "information":
      return "情報提供";
    case "facility_availablity":
      return "施設空き状況の相談";
    case "other":
      return "その他";
  }
}

export function postIndustryTranslater(postIndustry: PostIndustry) {
  switch (postIndustry) {
    case "nursing":
      return "介護施設";
    case "hospital":
      return "病院";
    case "other":
      return "その他施設";
    case "general":
      return "一般の方";
  }
}

export function postTermTranslater(postTerm: PostTerm) {
  switch (postTerm) {
    case "short_term":
      return "短期";
    case "long_term":
      return "長期";
    case "undecided":
      return "未定";
    default:
      return "未設定";
  }
}

export function postRoleTranslater(postRole: PostRole | string) {
  switch (postRole) {
    case "chief":
      return "所長";
    case "sub_chief":
      return "副所長";
    case "general_staff":
      return "一般スタッフ";
    case "other":
      return "その他";
    default:
      return postRole;
  }
}

export function postToTitle(post: Partial<PostClient>) {
  if (post.label === "lack_manpower" || post.label === "lack_supplies") {
    if (post.industryName) {
      return `${postLabelTranslater(post.label)} / ${post.industryName}`;
    } else {
      return `${postLabelTranslater(post.label)}`;
    }
  } else if (post.label === "facility_availablity") {
    const termText =
      (post.title as PostTerm) == "undecided"
        ? ""
        : `(${postTermTranslater(post.title as PostTerm)})`;

    if (post.industryName) {
      return `${postLabelTranslater(post.label)}${termText} / ${
        post.industryName
      }`;
    } else {
      return `${postLabelTranslater(post.label)}${termText}`;
    }
  } else {
    if (post.industryName) {
      return `${post.title || "投稿"} / ${post.industryName}`;
    } else {
      return `${post.title || "投稿"}`;
    }
  }
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("postToTitle", () => {
    expect(
      postToTitle({
        label: "facility_availablity",
        title: "short_term",
        industry: "nursing",
        industryName: "介護施設",
        role: "chief",
        content: "マスクが不足しています",
      })
    ).toBe("施設空き状況の相談(短期) / 介護施設");
    expect(
      postToTitle({
        label: "facility_availablity",
        title: "undecided",
        industry: "nursing",
        industryName: "介護施設",
        role: "chief",
        content: "マスクが不足しています",
      })
    ).toBe("施設空き状況の相談 / 介護施設");
    expect(
      postToTitle({
        id: 1,
        label: "lack_supplies",
        title: "short_term",
        industry: "nursing",
        industryName: "",
        role: "chief",
        content: "マスクが不足しています",
      })
    ).toBe("物品の不足");
    expect(
      postToTitle({
        id: 1,
        label: "lack_manpower",
        title: "short_term",
        industry: "nursing",
        industryName: "介護施設",
        role: "chief",
        content: "介護士が不足しています",
        createdAt: "2021-05-01T10:00:00.000+09:00",
        updatedAt: "2021-05-01T10:00:00.000+09:00",
      })
    ).toBe("人員の不足 / 介護施設");
  });
}
