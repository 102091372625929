<template>
  <div id="header" class="pt-3">
    <Toolbar>
      <template #start>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <BasicButton
              v-if="user"
              icon="pi pi-bars"
              class="me-2 menu-button"
              need-icon
              variant="secondary"
              outlined
              large
              @click="showHeader = !showHeader"
            />
            <h5 class="d-flex align-items-center">
              <PageTree v-if="pageTree.length" :tree="pageTree" />
              <b>{{ pageTitle }}</b>
            </h5>
          </div>
        </div>
      </template>
    </Toolbar>
    <Divider />
    <Sidebar v-if="user" v-model:visible="showHeader">
      <TieredMenu :model="headerItems" class="without-border w-100" />
    </Sidebar>
  </div>
</template>

<script lang="ts" setup>
import Sidebar from "primevue/sidebar";
import TieredMenu from "primevue/tieredmenu";
import Toolbar from "primevue/toolbar";
import Divider from "primevue/divider";
import type { MenuItem } from "primevue/menuitem";
import { ref, computed } from "vue";
import { BasicButton } from "/@/vue/components/Atom";
import { PageTree } from "/@/vue/components/Layouts";
import { useRouterUtil, useAuthorize } from "/@/vue/composables";
import type { User } from "/@/types/user";

interface Props {
  user?: User;
  isAdminPage?: boolean;
}

const props = defineProps<Props>();
defineEmits(["logout", "hideHeader"]);

const showHeader = ref<boolean>(false);

const { goto, currentRouteMeta } = useRouterUtil();

const pageTitle = computed(() => {
  return currentRouteMeta.value?.title;
});

const pageTree = computed(() => {
  return currentRouteMeta.value?.tree || [];
});

function commandHandler(command: () => void) {
  return () => {
    command();
    showHeader.value = false;
  };
}

const headerItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "管理トップ",
      icon: "pi pi-home",
      visible: props.user,
      command: commandHandler(() => goto({ name: "UserIndex" })),
    },
    {
      label: "事務局投稿管理",
      icon: "pi pi-exclamation-triangle",
      visible: props.user,
      command: commandHandler(() => goto({ name: "EmergencyNewsIndex" })),
    },
    {
      label: "ユーザー投稿管理",
      icon: "pi pi-users",
      visible: props.user,
      command: commandHandler(() => goto({ name: "UserPostsIndex" })),
    },
    {
      label: "ポータルサイトへ",
      icon: "pi pi-comments",
      visible: props.user,
      command: commandHandler(() => goto({ name: "Index" })),
    },
    {
      label: "ログアウト",
      icon: "pi pi-sign-out",
      visible: props.user,
      command: commandHandler(() => handleLogout()),
    },
  ];
});

// logout

const { logout } = useAuthorize();

async function handleLogout() {
  await logout();
}
</script>

<style lang="scss" scoped>
#header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  color: $main-gray-color;
  background-color: white;

  .p-toolbar {
    padding: 0;
  }

  .p-button {
    border: none;
  }

  .menu-button {
    .pi {
      font-size: 1.5rem;
    }
  }
}
.user-icon {
  border: 1px solid $main-gray-color;
  border-radius: 50%;
  height: 36px;
  width: 36px;
}
.user-mail {
  font-size: 50%;
}
</style>
