import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { OrganizationClientScheme } from "./organization.d";

export const UserScheme = z.object({
  name: z.string().trim().min(1),
  email: z.string().email(),
  password: z.string().min(1, { message: "パスワードが入力されていません" }),
  passwordConfirmation: z
    .string()
    .min(1, { message: "パスワードが入力されていません" }),
});

export type User = zod.infer<typeof UserScheme>;
export type UserAttributes = keyof User;

export const UserClientScheme = UserScheme.pick({
  name: true,
  email: true,
}).extend({
  organizations: OrganizationClientScheme.array(),
});

export type UserClient = zod.infer<typeof UserClientScheme>;

// session

type SingUpResponse = {
  status: "confirm" | "success" | "error";
  user?: User;
};

export const LoginScheme = z.object({
  email: z.string().email(),
  password: z.string().min(1, { message: "パスワードが入力されていません" }),
});

export const LoginFormScheme = LoginScheme.partial();
export type LoginForm = zod.infer<typeof LoginFormScheme>;
