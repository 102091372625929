<template>
  <div>
    <ReplyForm v-model:content="form.content" :errors="errors" />
    <div class="d-flex justify-content-center my-5">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-4"
        outlined
        @click="$emit('cancel')"
      />

      <BasicButton
        label="返信"
        variant="primary"
        button-type="submit"
        @click="submit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useZodScheme } from "/@/vue/composables";
import { ReplyForm } from "/@/vue/components/Organisms/Posts";
import { BasicButton } from "/@/vue/components/Atom";
import {
  PostReply,
  PostReplyForm as PostReplyFormType,
  PostReplyCheckScheme,
} from "/@/types";
import { onMounted } from "vue";

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "submit", prms: PostReply): void;
}>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<PostReplyFormType>(PostReplyCheckScheme);

onMounted(() => {
  startValidation.value = true;
});

function submit() {
  try {
    startValidation.value = true;

    const prms = PostReplyCheckScheme.parse(form);
    emit("submit", prms);
  } catch (e) {
    alert("入力内容に問題があります。");
    return;
  }
}
</script>

<style scoped></style>
