<template>
  <div>
    <div class="select-tab">
      <Dropdown
        v-model="activeIndex"
        :options="[
          { label: '県協会からの情報', value: 0 },
          { label: '行政からの情報', value: 1 },
          { label: '会員様からの情報', value: 2 },
          { label: '一般の方からの情報', value: 3 },
        ]"
        option-label="label"
        option-value="value"
        class="w-100"
      />
    </div>

    <TabView v-model:active-index="activeIndex" class="home-tab">
      <TabPanel header="県協会からの情報">
        <transition name="slide" mode="out-in">
          <div>
            <div
              class="d-flex flex-wrap justify-content-center align-items-center my-2"
              style="min-height: 76px"
            >
              <SimpleStyleSelectButton
                v-model="selectedNewsAreas"
                :options="newsAreas"
                multiple
                with-reset
                class="area-selector"
                :class="{
                  'ms-3': !isMobile,
                }"
              />
            </div>

            <div class="d-flex justify-content-end mb-2">
              <PageInfo
                :per-page="perPage"
                :total="associationNews.length"
                :first="currentAssociationNewsPage"
                style="font-size: 0.8rem"
                class="me-3"
              />
            </div>

            <template v-if="associationNews.length">
              <div class="d-flex flex-wrap justify-content-between news-wrapper">
                <template v-for="n in filteredAssociatonNews" :key="n.id">
                  <NewsCard
                    :news="n"
                    class="mb-5 mx-1"
                    :style="{
                      'max-width': isMobile ? '100%' : '100%',
                      width: isMobile ? '100%' : '30%',
                    }"
                    @goto-news-show="
                      goto({ name: 'NewsShow', params: { id: $event } })
                    "
                  />
                </template>
              </div>
              <div class="paginator">
                <Paginator
                  v-model:first="currentAssociationNewsPage"
                  :rows="perPage"
                  :total-records="associationNews.length"
                />
              </div>
            </template>
          </div>
        </transition>
      </TabPanel>
      <TabPanel header="行政からの情報">
        <div
          class="d-flex flex-wrap justify-content-center align-items-center my-2"
          style="min-height: 76px"
        >
          <SimpleStyleSelectButton
            v-model="selectedNewsAreas"
            :options="newsAreas"
            multiple
            with-reset
            class="area-selector"
            :class="{
              'ms-3': !isMobile,
            }"
          />
        </div>

        <div class="d-flex justify-content-end mb-2">
          <PageInfo
            :per-page="perPage"
            :total="administrationNews.length"
            :first="currentAdministrationNewsPage"
            style="font-size: 0.8rem"
            class="me-3"
          />
        </div>

        <template v-if="administrationNews.length">
          <div class="d-flex flex-wrap justify-content-between news-wrapper">
            <template v-for="n in filteredAdoministrationNews" :key="n.id">
              <NewsCard
                :news="n"
                class="mb-5 mx-1"
                :style="{
                  'max-width': isMobile ? '100%' : '100%',
                  width: isMobile ? '100%' : '30%',
                }"
                @goto-news-show="
                  goto({ name: 'NewsShow', params: { id: $event } })
                "
              />
            </template>
          </div>
          <div class="paginator">
            <Paginator
              v-model:first="currentAdministrationNewsPage"
              :rows="perPage"
              :total-records="administrationNews.length"
            />
          </div>
        </template>
      </TabPanel>
      <TabPanel header="会員様からの情報">
        <CreatePostButton
          class="w-100 d-flex justify-content-center my-2"
          style="min-height: 76px"
          @click="goto({ name: 'PostNew' })"
        />

        <div class="d-flex justify-content-end mb-2">
          <PageInfo
            :per-page="perPage"
            :total="memberPosts.length"
            :first="currentMemberPostPage"
            style="font-size: 0.8rem"
            class="me-3"
          />
        </div>

        <template v-if="memberPosts?.length">
          <div class="d-flex flex-wrap justify-content-between posts-wrapper">
            <template v-for="p in filteredMemberPosts" :key="p.id">
              <PostCard
                :post="p"
                class="mb-5 mx-1"
                :style="{
                  'max-width': isMobile ? '100%' : '100%',
                  width: isMobile ? '100%' : '30%',
                }"
                @goto-post-show="
                  goto({ name: 'PostShow', params: { id: $event } })
                "
              />
            </template>
          </div>
          <div class="paginator">
            <Paginator
              v-model:first="currentMemberPostPage"
              :rows="perPage"
              :total-records="memberPosts.length"
            />
          </div>
        </template>
      </TabPanel>
      <TabPanel header="一般の方からの情報">
        <CreatePostButton
          class="w-100 d-flex justify-content-center my-2"
          style="min-height: 76px"
          @click="goto({ name: 'PostNew' })"
        />

        <div class="d-flex justify-content-end mb-2">
          <PageInfo
            :per-page="perPage"
            :total="generalPosts.length"
            :first="currentGeneralPostPage"
            style="font-size: 0.8rem"
            class="me-3"
          />
        </div>

        <template v-if="generalPosts?.length">
          <div class="d-flex flex-wrap justify-content-between posts-wrapper">
            <template v-for="p in filteredGeneralPosts" :key="p.id">
              <PostCard
                :post="p"
                class="mb-5 mx-1"
                :style="{
                  'max-width': isMobile ? '100%' : '100%',
                  width: isMobile ? '100%' : '30%',
                }"
                @goto-post-show="
                  goto({ name: 'PostShow', params: { id: $event } })
                "
              />
            </template>
          </div>
          <div class="paginator">
            <Paginator
              v-model:first="currentGeneralPostPage"
              :rows="perPage"
              :total-records="generalPosts.length"
            />
          </div>
        </template>
      </TabPanel>
    </TabView>
  </div>
</template>

<script lang="ts" setup>
import _ from "lodash";
import { ref, computed, watch } from "vue";
import { getStorage, setStorage } from "/@/modules/localStorage";
import {
  useNews,
  usePosts,
  useRouterUtil,
  useMqUtils,
} from "/@/vue/composables";
import { SimpleStyleSelectButton } from "/@/vue/components/Atom";
import { PageInfo } from "/@/vue/components/Molecules/UI";
import { Card as NewsCard } from "/@/vue/components/Molecules/News";
import { Card as PostCard } from "/@/vue/components/Molecules/Post";
import { CreatePostButton } from "/@/vue/components/Molecules/Button";
import { newsAreas } from "/@/types/news.d";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Paginator from "primevue/paginator";
import Dropdown from "primevue/dropdown";

const { isMobile } = useMqUtils();

const { goto } = useRouterUtil();

const { getNews } = useNews();
const { data: news } = getNews();

const { getPosts } = usePosts();
const { data: posts } = getPosts();

const activeIndex = ref(getStorage("homeTabActiveIndex") || 0);
watch(activeIndex, (v) => setStorage("homeTabActiveIndex", v));

const perPage = computed(() => {
  if (isMobile.value) return 6;
  return 9;
});

// news

const selectedNewsAreas = ref<string[]>(
  getStorage("selectedNewsAreas") || [...newsAreas]
);
watch(selectedNewsAreas, (v) => {
  currentAdministrationNewsPage.value = 0;
  currentAssociationNewsPage.value = 0;
  setStorage("selectedNewsAreas", v);
});

const areadNews = computed(() => {
  if (!news.value) return [];
  return news.value.filter(
    (n) =>
      !n.areas.length ||
      !selectedNewsAreas.value.length ||
      _.intersection(n.areas, selectedNewsAreas.value).length
  );
});

const administarationLabels = [
  "announce_from_prefecture",
  "announce_from_city",
  "announce_from_country",
];

const currentAdministrationNewsPage = ref(0);

const administrationNews = computed(() => {
  return areadNews.value.filter((n) => administarationLabels.includes(n.label));
});

const filteredAdoministrationNews = computed(() => {
  return administrationNews.value.slice(
    currentAdministrationNewsPage.value,
    currentAdministrationNewsPage.value + perPage.value
  );
});

const currentAssociationNewsPage = ref(0);

const associationNews = computed(() => {
  return areadNews.value.filter(
    (n) => !administarationLabels.includes(n.label)
  );
});

const filteredAssociatonNews = computed(() => {
  return associationNews.value.slice(
    currentAssociationNewsPage.value,
    currentAssociationNewsPage.value + perPage.value
  );
});

// post

const postTypes = ["member", "general"];

const currentMemberPostPage = ref(0);

const memberPosts = computed(() => {
  if (!posts.value) return [];
  return posts.value.filter((p) => p.industry !== "general");
});

const filteredMemberPosts = computed(() => {
  return memberPosts.value.slice(
    currentMemberPostPage.value,
    currentMemberPostPage.value + perPage.value
  );
});

const generalPosts = computed(() => {
  if (!posts.value) return [];
  return posts.value.filter((p) => p.industry === "general");
});

const currentGeneralPostPage = ref(0);

const filteredGeneralPosts = computed(() => {
  return generalPosts.value.slice(
    currentMemberPostPage.value,
    currentMemberPostPage.value + perPage.value
  );
});
</script>

<style lang="scss">
.news-wrapper::after,
.posts-wrapper::after {
  content: "";
  display: block;
  width: 30%;
  margin-right: 0.5rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 990px) {
    display: none;
  }
}

.select-tab {
  visibility: hidden;
  height: 1px;

  @media screen and (max-width: 768px) {
    visibility: visible;
    height: auto;
  }

  .p-dropdown-label {
    padding-left: calc(36px + 0.75rem);
    text-align: center;
  }
}

.area-selector {
  @media screen and (max-width: 425px) {
    margin: 0 -2rem;
  }
}

.home-tab {
  .p-tabview-panels {
    @media screen and (max-width: 768px) {
      padding-bottom: 0.5rem;
    }
  }

  .p-tabview-nav {
    border: none;

    @media screen and (max-width: 768px) {
      visibility: hidden;
      height: 1px;
    }
  }

  .p-tabview-header {
    width: 100%;

    .p-tabview-nav-link {
      display: flex;
      justify-content: center;
      font-weight: 400;
      font-size: 1.1rem;

      border-left: solid 1px #4c4c4c;
      border-top-left-radius: 0;
      border-bottom: none;
    }

    &.p-highlight {
      .p-tabview-nav-link {
        font-weight: 700;
        font-size: 120%;

        border-bottom: 4px solid #e5e7eb;
      }
    }

    &:nth-child(4) {
      .p-tabview-nav-link {
        border-right: solid 1px #4c4c4c;
        border-top-right-radius: 0;
      }
    }
  }

  .p-card {
    transition: all 0.05s ease-in-out;
    -ms-overflow-style: none;
    scrollbar-width: none;

    font-family: "Kosugi Maru", sans-serif;

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover {
      box-shadow: 3px 6px 6px 2px #e9ecef;
      transform: scale(1.01);
    }
  }

  .p-card-title {
    font-size: 115%;
    margin-bottom: 0.5rem;
    border-bottom: 2px solid #e5e7eb;

    @media screen and (max-width: 768px) {
      font-size: 120%;
    }
  }

  .p-card-subtitle {
    margin-bottom: 0;
    font-size: 90%;

    @media screen and (max-width: 768px) {
      font-size: 95%;
    }
  }

  .p-card-content {
    font-size: 90%;
    word-wrap: break-word;
    word-break: break-word;
    padding-top: 0.5rem;

    img {
      max-width: 100%;
      max-height: 200px;
      display: block;
      margin: auto;
    }

    @media screen and (max-width: 768px) {
      font-size: 95%;
    }
  }
}

.p-card-content {
  ol,
  ul {
    padding-left: 1.5rem;
  }
}

.p-paginator {
  padding-bottom: 0;
}
</style>
