<template>
  <div>
    <div
      style="height: 50px"
      class="d-flex align-items-center justify-content-end mb-3"
    >
      <div class="d-flex align-items-center">
        <BasicButton
          icon="pi pi-trash"
          label="ゴミ箱"
          variant="danger"
          outlined
          @click="goto({ name: 'UserPostsTrashIndex' })"
        />
      </div>
    </div>

    <PostTable
      v-if="posts"
      :posts="posts"
      @select-post="selectPost"
      @trash-post="handleTrashPosts"
    />
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil, usePosts } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as PostTable } from "/@/vue/components/Organisms/Posts";

const { goto } = useRouterUtil();

const { getPosts, trashPost } = usePosts();
const { data: posts, mutate: getPostsMutate } = getPosts({
  all: true,
});

function selectPost(id: number) {
  goto({ name: "UserPostShow", params: { id } });
}

async function handleTrashPosts(id: number | number[]) {
  if (!window.confirm("ゴミ箱に移動しますか？")) {
    return;
  }

  if (await trashPost(id)) {
    alert("ゴミ箱に移動しました");
    getPostsMutate();
    goto({ name: "UserPostsIndex" });
  } else {
    alert("ゴミ箱に移動に失敗しました");
  }
}
</script>

<style lang="scss" scoped></style>
