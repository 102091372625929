import { Ref, ComputedRef } from "vue";
import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { PostClient, Post, PostReply } from "/@/types/post";

export function usePosts() {
  function getPostsItemKey(
    id?: Ref<number>,
    options: { admin?: boolean } = {}
  ) {
    const { admin } = options;

    if (!id || !id.value) {
      return null;
    }

    let uri = `/api/v1/posts/${id.value}?`;

    if (admin) {
      uri += "admin=true&";
    }

    return uri;
  }

  function getPostsItem(
    id?: Ref<number> | ComputedRef<number>,
    option: { admin?: boolean } = {}
  ) {
    return useSWRV<PostClient>(() => getPostsItemKey(id, option), fetcher);
  }

  function getPosts(options: { all?: boolean } = {}) {
    let uri = "/api/v1/posts?";

    if (options.all) {
      uri += "all=true&";
    }

    return useSWRV<PostClient[]>(uri, fetcher);
  }

  async function createPost(post: Post) {
    try {
      const { data } = await axios.post<PostClient>("/api/v1/posts", {
        posts: post,
      });
      return data;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyPost(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.post("/api/v1/posts/bulk_destroy", {
          posts: {
            ids: id,
          },
        });
        return true;
      } else {
        await axios.delete(`/api/v1/posts/${id}`);
      }

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updatePost(id: number, post: Post) {
    try {
      const { data } = await axios.post(`/api/v1/posts/${id}`, {
        posts: post,
      });
      return data;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // trash

  function getTrashedPosts() {
    return useSWRV<PostClient[]>("/api/v1/posts/trashed_index", fetcher);
  }

  async function trashPost(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.post("/api/v1/posts/bulk_trash", {
          posts: {
            ids: id,
          },
        });
      } else {
        await axios.delete(`/api/v1/posts/${id}/trash`);
      }
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function restorePost(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.post("/api/v1/posts/bulk_restore", {
          posts: {
            ids: id,
          },
        });
        return true;
      } else {
        await axios.patch(`/api/v1/posts/${id}/restore`);
      }
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // reply

  async function createReply(postId: number, reply: PostReply) {
    try {
      const { data } = await axios.post<PostClient>(
        `/api/v1/posts/${postId}/post_replies`,
        {
          post_replies: reply,
        }
      );
      return data;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyReply(replyId: number) {
    try {
      await axios.delete(`/api/v1/post_replies/${replyId}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getPostsItem,
    getPosts,
    createPost,
    updatePost,
    destroyPost,

    // trash
    getTrashedPosts,
    trashPost,
    restorePost,

    // reply
    createReply,
    destroyReply,
  };
}
