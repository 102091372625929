<template>
  <div>
    <div class="mb-3 d-flex align-items-center" style="height: 50px"></div>

    <NewsForm
      v-model:title="form.title"
      v-model:content="form.content"
      v-model:published-at="form.publishedAt"
      v-model:area="form.area"
      v-model:areas="form.areas"
      v-model:label="form.label"
      v-model:new-attached-pdfs="form.newAttachedPdfs"
      :attached-pdfs="[]"
      is-emergency
      :user="user"
      :errors="errors"
    />

    <div class="d-flex justify-content-center my-5">
      <BasicButton
        icon="pi pi-chevron-circle-left"
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto"
      />
      <BasicButton
        icon="pi pi-send"
        button-type="submit"
        label="作成"
        @click="submit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";
import {
  useZodScheme,
  useNews,
  useRouterUtil,
  useUser,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as NewsForm } from "/@/vue/components/Organisms/Users/News";
import { NewsCheckScheme, NewsForm as NewsFormType } from "/@/types/news.d";

const { getUserInfo } = useUser();
const { data: user } = getUserInfo();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<NewsFormType>(
  NewsCheckScheme,
  {
    label: "announce_from_prefecture_association",
    showTop: true,
    area: "能登北部",
    areas: [],
    newAttachedPdfs: [],
  }
);

watch(form, (f) => {
  if (f.title && f.title.length > 20) {
    form.title = f.title.slice(0, 20);
  }
});

onMounted(() => {
  startValidation.value = true;
});

const { goto, backto } = useRouterUtil();
const { createNews } = useNews();

async function submit() {
  try {
    startValidation.value = true;

    const args = NewsCheckScheme.parse(form);

    if (await createNews(args)) {
      alert("作成に成功しました");
      goto({ name: "EmergencyNewsIndex" });
    } else {
      alert(
        "作成に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります");
    console.error(e);
  }
}
</script>

<style scoped></style>
