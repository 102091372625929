import i18next from "i18next";
import { z as zodCore } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import translation from "zod-i18n-map/locales/ja/zod.json";
import { ZodFormattedErrors } from "/@/types";

export function zodInit() {
  i18next.init({
    lng: "ja",
    resources: {
      ja: { zod: translation },
    },
    interpolation: {
      skipOnVariables: false,
    },
  });

  zodCore.setErrorMap(zodI18nMap);

  return zodCore;
}

export const z = zodInit();

export function getZodErrors(
  scheme: zodCore.ZodSchema,
  target: Object,
  afterSubmit: boolean | undefined
) {
  if (!afterSubmit) {
    return {};
  }

  const parsed = scheme.safeParse(target);

  if (parsed.success) {
    return {};
  } else {
    const formattedError = parsed.error.format();
    return formattedError;
  }
}

export function getErrorMessagesFromErrors(
  errors: ZodFormattedErrors,
  target: string
) {
  const keyError = errors[target];

  if (keyError && keyError._errors.length) {
    return keyError._errors;
  } else if (keyError) {
    return ["has_nested_error"];
  } else {
    return [];
  }
}
