<template>
  <div>
    <div
      style="height: 50px"
      class="d-flex align-items-center justify-content-end mb-3"
    >
      <BasicButton
        icon="pi pi-plus"
        label="投稿一覧へ"
        class="add-btn"
        variant="secondary"
        outlined
        @click="goto({ name: 'UserPostsIndex' })"
      />
    </div>

    <span v-if="posts && !posts.length">ゴミ箱は空です</span>
    <PostTable
      v-else-if="posts"
      :posts="posts"
      trashed
      @select-post="selectPost"
      @trash-post="handleDestroyPosts"
      @restore-post="handleRestorePosts"
    />
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { useRouterUtil, usePosts } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as PostTable } from "/@/vue/components/Organisms/Posts";

const { goto } = useRouterUtil();

const { getTrashedPosts, destroyPost, restorePost } = usePosts();
const { data: posts, mutate: getPostsMutate } = getTrashedPosts();

function selectPost(id: number) {
  goto({ name: "UserPostShow", params: { id } });
}

async function handleDestroyPosts(id: number | number[]) {
  if (!window.confirm("完全に削除しますか？この操作は取り消せません。")) {
    return;
  }

  if (await destroyPost(id)) {
    alert("削除しました");
    getPostsMutate();
  } else {
    alert("削除に失敗しました");
  }
}

async function handleRestorePosts(id: number | number[]) {
  if (!window.confirm("復元しますか？")) {
    return;
  }

  if (await restorePost(id)) {
    alert("復元しました");
    getPostsMutate();
  } else {
    alert(
      "復元に失敗しました。時間をおいて再度お試しください。解決しない場合は開発会社にお問い合わせください。"
    );
  }
}
</script>

<style lang="scss" scoped></style>
