import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const postLabels = [
  "lack_supplies",
  "lack_manpower",
  "facility_availablity",
  "information",
  "other",
] as const;

export const postTerms = ["short_term", "long_term", "undecided"] as const;
export const PostTermScheme = z.enum(postTerms);
export type PostTerm = zod.infer<typeof PostTermScheme>;

export const postIndustries = [
  "nursing",
  "hospital",
  "other",
  "general",
] as const;
export const PostIndustryScheme = z.enum(postIndustries);
export type PostIndustry = zod.infer<typeof PostIndustryScheme>;

export const PostLabelScheme = z.enum(postLabels);
export type PostLabel = zod.infer<typeof PostLabelScheme>;

export const postRoles = [
  "chief",
  "sub_chief",
  "general_staff",
  "other",
] as const;
export const PostRoleScheme = z.enum(postRoles);
export type PostRole = zod.infer<typeof PostRoleScheme>;

export const PostLabelTitleScheme = z.object({
  label: PostLabelScheme,
  title: z.string().max(20).optional(),
});

export const PostLabelTitleWithRefineScheme = PostLabelTitleScheme.refine(
  ({ label, title }) => {
    return (label != "information" && label != "other") || !!title;
  },
  {
    path: ["title"],
  }
);

const postRestObj = {
  industry: PostIndustryScheme,
  industryName: z.string().trim().min(0).max(20).optional(),
  role: PostRoleScheme.optional(),
  roleName: z.string().trim().min(0).optional(),
  content: z.string().trim().min(1),
  area: z.string().trim().min(0).max(20).optional(),
  name: z.string().trim().min(1).max(20),
  phoneNumber: z.string().min(10).max(11),
  email: z.string().trim().email().optional().or(z.literal("")),
};

export const PostScheme = PostLabelTitleScheme.extend(postRestObj);

export const PostCheckScheme = z.intersection(
  PostLabelTitleWithRefineScheme,
  z.object(postRestObj)
);

export type Post = zod.infer<typeof PostScheme>;

export const PostFormScheme = PostScheme.partial().extend({
  label: PostLabelScheme,
});
export type PostForm = zod.infer<typeof PostFormScheme>;

// reply

export const PostReplyScheme = z.object({
  content: z.string().trim().min(1),
});

export type PostReply = zod.infer<typeof PostReplyScheme>;

export const PostReplyFormScheme = PostReplyScheme.partial();
export type PostReplyForm = zod.infer<typeof PostReplyFormScheme>;

export const PostReplyCheckScheme = PostReplyScheme;

export const PostReplyClientScheme = PostReplyScheme.extend({
  id: z.number(),
  postId: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
});
export type PostReplyClient = zod.infer<typeof PostReplyClientScheme>;

// post client

export const PostClientScheme = PostScheme.extend({
  id: z.number(),
  trashed: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
  postReplies: PostReplyClientScheme.array().min(0),
});
export type PostClient = zod.infer<typeof PostClientScheme>;
