import { ComputedRef, Ref } from "vue";
import useSWRV from "swrv";
import { axios, fetcher, undefineKeyToNull } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { News, NewsClient, NewsLabel } from "/@/types/news";

export function useNews() {
  function getNewsItemKey(id?: Ref<number>, options: { admin?: boolean } = {}) {
    const { admin } = options;

    if (!id || !id.value) {
      return null;
    }

    let uri = `/api/v1/news/${id.value}?`;

    if (admin) {
      uri += "admin=true&";
    }

    return uri;
  }

  function getNewsItem(
    id?: Ref<number> | ComputedRef<number>,
    options: { admin?: boolean } = {}
  ) {
    return useSWRV<NewsClient>(() => getNewsItemKey(id, options), fetcher);
  }

  function getNews(options: { newsTypes?: NewsLabel[]; all?: boolean } = {}) {
    const { newsTypes, all } = options;

    let uri = "/api/v1/news?";

    if (newsTypes) {
      uri += `newsTypes=${newsTypes.join(",")}&`;
    }

    if (all) {
      uri += `all=true`;
    }

    return useSWRV<NewsClient[]>(uri, fetcher);
  }

  async function createNews(news: News) {
    try {
      await axios.post("/api/v1/news", {
        news,
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  async function updateNews(id: number, news: News) {
    try {
      await axios.patch(`/api/v1/news/${id}`, {
        news: undefineKeyToNull(news),
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  async function destroyNews(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.post("/api/v1/news/bulk_destroy", {
          news: {
            ids: id,
          },
        });
        return true;
      } else {
        await axios.delete(`/api/v1/news/${id}`);
      }
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  // trash

  function getTrashedNews() {
    return useSWRV<NewsClient[]>("/api/v1/news/trashed_index", fetcher);
  }

  async function trashNews(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.post("/api/v1/news/bulk_trash", {
          news: {
            ids: id,
          },
        });
      } else {
        await axios.delete(`/api/v1/news/${id}/trash`);
      }
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  async function restoreNews(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.post("/api/v1/news/bulk_restore", {
          news: {
            ids: id,
          },
        });
        return true;
      } else {
        await axios.patch(`/api/v1/news/${id}/restore`);
      }

      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  return {
    getNewsItem,
    getNews,
    createNews,
    updateNews,
    destroyNews,

    // trash
    getTrashedNews,
    trashNews,
    restoreNews,
  };
}
