<template>
  <input
    :id="formId"
    :value="value"
    :class="formClass"
    :type="formType"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    @input="update"
    @compositionstart="compositionStart"
    @compositionend="compositionEnd"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

interface Props {
  value?: string;
  isValid?: boolean;
  formId?: string;
  placeholder?: string;
  autocomplete?: string;
  formType?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:value", value: string | null): void;
}>();

const formClass = computed(() => {
  return {
    "form-control": true,
    "is-invalid": !props.isValid,
  };
});

const compositioning = ref(false);

function update(e: { target: HTMLInputElement }) {
  if (compositioning.value) return;

  if (e.target instanceof HTMLInputElement) {
    emit("update:value", e.target.value);
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}

function compositionStart() {
  compositioning.value = true;
}

function compositionEnd(e: { target: HTMLInputElement }) {
  compositioning.value = false;
  emit("update:value", e.target.value);
}
</script>

<style lang="scss" scoped></style>
