<template>
  <div>
    <form @submit.prevent="login">
      <div class="mb-3">
        <FormItem
          v-model:value="loginForm.email"
          :errors="errors"
          label="メール"
          form-id="email"
          form-type="email"
        />
      </div>

      <FormItem
        v-model:value="loginForm.password"
        :errors="errors"
        label="パスワード"
        form-id="password"
        form-type="password"
      />
      <div class="mb-3"></div>
      <slot></slot>
      <div v-if="!loading" class="mt-3">
        <BasicButton button-type="submit" @click="login">
          ログイン
        </BasicButton>
      </div>
      <i v-else class="pi pi-spin pi-spinner"></i>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useZodScheme } from "/@/vue/composables/zod";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";
import { User, LoginForm, LoginScheme } from "/@/types";

interface Props {
  loading?: boolean;
}

interface Emits {
  (e: "login", user: User): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();

// form

const { useFormAndErrors } = useZodScheme();
const {
  form: loginForm,
  errors,
  startValidation,
} = useFormAndErrors<LoginForm>(LoginScheme);

function login() {
  try {
    startValidation.value = true;
    const user = LoginScheme.parse(loginForm);
    emit("login", user);
  } catch (e) {
    console.error("");
  }
}
</script>

<style scoped></style>
