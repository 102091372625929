<template>
  <div>
    <div class="mb-4">
      <FormLabel label="組織" />
      <span>{{ user?.organizations.map((o) => o.name).join("・") }}</span>
    </div>

    <div class="mb-4">
      <FormLabel label="タイトル選択" />
      <Dropdown
        :options="addExplanationLabels"
        :model-value="label"
        option-label="label"
        option-value="value"
        class="w-100"
        @update:model-value="updateLabel"
      />
    </div>

    <div v-if="label === 'information' || label === 'other'" class="mb-4">
      <FormLabel label="タイトル（必須） ※20文字以内" />
      <FormItem
        :value="title"
        :errors="errors"
        form-id="title"
        form-type="text"
        @update:value="$emit('update:title', $event as string | undefined)"
      />
    </div>

    <div class="mb-4">
      <FormLabel label="地区選択" />
      <div class="d-felx justify-content-start">
        <SimpleStyleSelectButton
          :model-value="areas"
          :options="addedNewsAreas"
          multiple
          with-reset
          news
          style="width: auto"
          @update:model-value="updateAreas"
        />
      </div>
    </div>

    <div class="mb-4">
      <FormLabel label="本文（必須）" />
      <FormItemWrapper :errors="errors" form-id="content">
        <Editor
          :model-value="content"
          editor-style="height: 320px"
          :class="{
            'is-invalid': errors.content?._errors?.length,
          }"
          @update:model-value="
            $emit('update:content', $event as string | undefined)
          "
        >
          <template #toolbar>
            <span class="ql-formats">
              <select class="ql-size me-3">
                <option value="huge">見出し</option>
                <option value="large">小見出し</option>
                <!-- Note a missing, thus falsy value, is used to reset to default -->
                <option selected>通常</option>
              </select>
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-strike me-3"></button>

              <select class="ql-color ql-picker ql-icon-picker"></select>
              <select
                class="ql-background ql-picker ql-icon-picker me-3"
              ></select>

              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
              <select class="ql-align ql-picker ql-icon-picker me-3">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>

              <button class="ql-link"></button>
              <button class="ql-image me-3"></button>

              <button class="ql-clean me-3"></button>
            </span>
          </template>
        </Editor>
      </FormItemWrapper>
    </div>

    <template v-if="!isEmergency">
      <div class="d-flex flex-wrap mb-4">
        <div class="mb-2 me-2">
          <FormLabel label="開催日" />
          <FormItem
            :value="heldAt"
            :is-valid="!heldAt"
            :errors="errors"
            form-id="heldAt"
            form-type="date"
            style="width: 210px"
            @update:value="$emit('update:heldAt', $event as string | undefined)"
          />
        </div>

        <div class="mb-2">
          <FormItemTime
            :value="heldAt"
            :env="heldAtEnv"
            :errors="errors"
            form-id="heldAt"
            label="時間"
            style="width: 120px"
            @update:value="$emit('update:heldAt', $event as string | undefined)"
          />
        </div>
      </div>

      <div class="mb-4">
        <FormLabel label="場所" />
        <FormItem
          :value="place"
          :errors="errors"
          form-id="place"
          form-type="textarea"
          @update:value="$emit('update:place', $event as string | undefined)"
        />
      </div>

      <div class="mb-4">
        <FormLabel label="講師" />
        <FormItem
          :value="lecturer"
          :errors="errors"
          form-id="lecturer"
          form-type="textarea"
          @update:value="$emit('update:lecturer', $event as string | undefined)"
        />
      </div>

      <div class="mb-4">
        <FormLabel label="費用" />
        <FormItem
          :value="cost"
          :errors="errors"
          form-id="cost"
          form-type="textarea"
          @update:value="$emit('update:cost', $event as string | undefined)"
        />
      </div>

      <div class="mb-4">
        <FormLabel label="申込方法" />
        <FormItem
          :value="applicationMethod"
          :errors="errors"
          form-id="applicationMethod"
          form-type="textarea"
          @update:value="
            $emit('update:applicationMethod', $event as string | undefined)
          "
        />
      </div>
    </template>

    <div v-if="formAttachedPdfs.length" class="mb-4">
      <div v-for="pdf in formAttachedPdfs" :key="pdf.signedId">
        <FormLabel label="添付書類" />
        <div class="d-flex flex-wrap align-items-center">
          <a
            :href="pdf.url"
            target="_blank"
            class="me-3 d-flex align-items-center"
          >
            <span class="me-2">添付書類を確認</span>
            <i class="pi pi-external-link"></i>
          </a>

          <BasicButton
            icon="pi pi-trash"
            need-icon
            variant="danger"
            text
            @click="removeAttachedPdf(pdf)"
          />
        </div>
      </div>
    </div>

    <PdfForm
      :model-value="newAttachedPdfs"
      class="mb-3"
      @update:model-value="$emit('update:newAttachedPdfs', $event)"
    />

    <div class="mb-4">
      <FormLabel label="公開日時（未設定の場合は即座に公開されます）" />
      <div class="d-flex">
        <FormItem
          :value="publishedAt"
          :errors="errors"
          form-id="publishedAt"
          form-type="date"
          :min-date="now"
          class="me-2"
          style="width: 210px; min-width: 210px"
          @update:value="
            $emit('update:publishedAt', $event as string | undefined)
          "
        />
        <FormItemTime
          :value="publishedAt"
          :env="publishedAtEnv"
          :errors="errors"
          form-id="publishedAt"
          label=""
          :minutes-increment="1"
          :style="
            isMobile
              ? {
                  width: '100%',
                }
              : {
                  width: '120px',
                }
          "
          @update:value="
            $emit('update:publishedAt', $event as string | undefined)
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { fromISO, luxonNow } from "/@/modules/luxon";
import { labelTranslater } from "/@/modules/news";
import { useMqUtils } from "/@/vue/composables";
import {
  SimpleStyleSelectButton,
  Label as FormLabel,
  BasicButton,
} from "/@/vue/components/Atom";
import {
  FormItem,
  FormItemWrapper,
  FormItemTime,
  PdfForm,
} from "/@/vue/components/Molecules/Form";
import { ZodFormattedErrors, UserClient } from "/@/types";
import Editor from "primevue/editor";
import Dropdown from "primevue/dropdown";
import { NewsLabel, newsLabels, newsAreas } from "/@/types/news.d";

const props = defineProps<{
  user?: UserClient;
  label: NewsLabel;
  title?: string;
  content?: string;
  heldAt?: string;
  place?: string;
  area?: string;
  areas: string[];
  lecturer?: string;
  cost?: string;
  applicationMethod?: string;
  publishedAt?: string;
  newAttachedPdfs: string[];
  attachedPdfs: { url: string; signedId: string }[];
  errors: ZodFormattedErrors;
  isEmergency?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:label", value?: NewsLabel): void;
  (e: "update:title", value?: string): void;
  (e: "update:content", value?: string): void;
  (e: "update:heldAt", value?: string): void;
  (e: "update:place", value?: string): void;
  (e: "update:area", value?: string): void;
  (e: "update:areas", value?: string[]): void;
  (e: "update:lecturer", value?: string): void;
  (e: "update:cost", value?: string): void;
  (e: "update:applicationMethod", value?: string): void;
  (e: "update:publishedAt", value?: string): void;
  (e: "update:newAttachedPdfs", value?: string[]): void;
}>();

const { isMobile } = useMqUtils();

const addExplanationLabels = computed(() => {
  return newsLabels.map((l) => {
    if (
      [
        "announce_from_prefecture",
        "announce_from_country",
        "announce_from_city",
      ].includes(l)
    ) {
      return {
        label: `${labelTranslater(l)}　※「行政からの情報」に表示`,
        value: l,
      };
    } else {
      return {
        label: labelTranslater(l),
        value: l,
      };
    }
  });
});

const addedNewsAreas = computed(() => {
  return newsAreas.concat("(指定なし)");
});

const heldAtEnv = computed(() => {
  if (!props.heldAt) return;

  return fromISO(props.heldAt)?.toObject();
});

const publishedAtEnv = computed(() => {
  if (!props.publishedAt) return;

  return fromISO(props.publishedAt)?.toObject();
});

const now = computed(() => {
  return luxonNow().startOf("day");
});

function updateLabel(e: string) {
  if (e !== "information" && e !== "other") {
    emit("update:title", undefined);
  }
  emit("update:label", e as NewsLabel);
}

function updateArea(e?: string) {
  if (e === "(指定なし)") {
    emit("update:area", undefined);
  } else {
    emit("update:area", e);
  }
}

function updateAreas(e: string[]) {
  emit(
    "update:areas",
    newsAreas.filter((a) => e.includes(a))
  );
}

function removeAttachedPdf(pdf: { url: string; signedId: string }) {
  const rest = props.newAttachedPdfs.filter((p) => p !== pdf.signedId);
  emit("update:newAttachedPdfs", rest);
}

const formAttachedPdfs = computed(() => {
  if (!props.newAttachedPdfs) return [];

  return props.attachedPdfs.filter((pdf) => {
    return props.newAttachedPdfs.some((signedId) => pdf.signedId === signedId);
  });
});
</script>

<style>
img {
  max-width: 300px;
  max-height: 400px;
}
</style>
