import useSWRV from "swrv";
import { computed } from "vue";
import { User, UserClient } from "/@/types/user.d";
import { useStore } from "/@/vue/store";
import { fetcher } from "/@/modules/axios";

export function useUser() {
  const store = useStore();

  const user = computed<User | undefined>(() => {
    return store.state.user.user;
  });

  async function getUser() {
    await store.dispatch("user/GET_USER");
  }

  function getUserInfo() {
    return useSWRV<UserClient>("/api/v1/current_user", fetcher);
  }

  return {
    user,
    getUser,
    getUserInfo,
  };
}
