import { basicFormatter } from "./luxon";
import { NewsClient, NewsLabel, newsAreas } from "/@/types/news.d";

export function labelTranslater(label: NewsLabel) {
  switch (label) {
    case "announce_from_prefecture_association":
      return "県協会からの案内";
    case "current_activity_prefecture_association":
      return "県協会の現在の動き";
    case "caution":
      return "注意喚起";
    case "information":
      return "情報提供";
    case "other":
      return "自由入力";
    case "announce_from_prefecture":
      return "石川県からの案内";
    case "announce_from_city":
      return "市町村からの案内";
    case "announce_from_country":
      return "国からの案内";
  }
}

export function newsToTitle(news: NewsClient) {
  if (news.label === "current_activity_prefecture_association") {
    return `${labelTranslater(news.label)} / ${basicFormatter(
      news.publishedAt,
      "onlyDayMonth"
    )}付`;
  }

  if (news.label === "other" || news.label === "information") {
    return news.title || "投稿";
  }

  return labelTranslater(news.label);
}

export function areasToStr(news: NewsClient) {
  if (!news.areas.length || newsAreas.length === news.areas.length) {
    return "全地区";
  }

  return news.areas.join(" / ");
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("labelTranslater", () => {
    expect(labelTranslater("announce_from_prefecture_association")).toBe(
      "県協会からの案内"
    );
    expect(labelTranslater("current_activity_prefecture_association")).toBe(
      "県協会の現在の動き"
    );
    expect(labelTranslater("caution")).toBe("注意喚起");
    expect(labelTranslater("information")).toBe("情報提供");
    expect(labelTranslater("other")).toBe("自由入力");
    expect(labelTranslater("announce_from_prefecture")).toBe(
      "石川県からの案内"
    );
    expect(labelTranslater("announce_from_city")).toBe("市町村からの案内");
    expect(labelTranslater("announce_from_country")).toBe("国からの案内");
  });

  it("newsToTitle", () => {
    expect(
      newsToTitle({
        label: "announce_from_prefecture_association",
        publishedAt: "2021-08-01T00:00:00.000Z",
      } as NewsClient)
    ).toBe("県協会からの案内");
    expect(
      newsToTitle({
        label: "information",
        publishedAt: "2021-08-01T00:00:00.000Z",
        title: "情報提供のタイトル",
      } as NewsClient)
    ).toBe("情報提供のタイトル");
    expect(
      newsToTitle({
        label: "other",
        publishedAt: "2021-08-01T00:00:00.000Z",
      } as NewsClient)
    ).toBe("投稿");
    expect(
      newsToTitle({
        label: "other",
        publishedAt: "2021-08-01T00:00:00.000Z",
        title: "投稿のタイトル",
      } as NewsClient)
    ).toBe("投稿のタイトル");
    expect(
      newsToTitle({
        label: "current_activity_prefecture_association",
        publishedAt: "2021-08-01T00:00:00.000Z",
      } as NewsClient)
    ).toBe("県協会の現在の動き / 8月1日付");
  });

  it("areaToStr", () => {
    expect(areasToStr({ areas: [] as string[] } as NewsClient)).toBe("全地区");
    expect(areasToStr({ areas: newsAreas } as NewsClient)).toBe("全地区");
    expect(areasToStr({ areas: ["能登北部"] } as NewsClient)).toBe("能登北部");
    expect(areasToStr({ areas: ["能登北部", "能登中部"] } as NewsClient)).toBe(
      "能登北部 / 能登中部"
    );
  });
}
