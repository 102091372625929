import { computed } from "vue";
import { useMq } from "vue3-mq";

export function useMqUtils() {
  const mq = useMq();
  const isMobile = computed(() => ["xs", "sm", "md"].includes(mq.current));
  const isXs = computed(() => mq.current === "xs");
  const isSm = computed(() => mq.current === "sm");
  const isMd = computed(() => mq.current === "md");

  return {
    isMobile,
    isXs,
    isSm,
    isMd,
  };
}
