import Index from "./Index.vue";
import Edit from "./Edit.vue";
import New from "./New.vue";
import Show from "./Show.vue";
import Trash from "./Trash.vue";

export default [
  {
    path: "",
    name: "EmergencyNewsIndex",
    component: Index,
    meta: {
      title: "事務局投稿管理",
    },
  },
  {
    path: "edit/:id",
    name: "EmergencyNewsEdit",
    component: Edit,
    meta: {
      title: "編集",
      tree: [
        {
          title: "事務局投稿管理",
          name: "EmergencyNewsIndex",
        },
        {
          title: "詳細",
          name: "EmergencyNewsShow",
        },
      ],
    },
  },
  {
    path: "show/:id",
    name: "EmergencyNewsShow",
    component: Show,
    meta: {
      title: "詳細",
      tree: [
        {
          title: "事務局投稿管理",
          name: "EmergencyNewsIndex",
        },
      ],
    },
  },
  {
    path: "new",
    name: "EmergencyNewsNew",
    component: New,
    meta: {
      title: "新規投稿",
      tree: [
        {
          title: "事務局投稿管理",
          name: "EmergencyNewsIndex",
        },
      ],
    },
  },
  {
    path: "trash",
    name: "EmergencyNewsTrashIndex",
    component: Trash,
    meta: {
      title: "ゴミ箱",
      tree: [
        {
          title: "事務局投稿管理",
          name: "EmergencyNewsIndex",
        },
      ],
    },
  },
];
