import Axios from "axios";
import { version } from "./version";

export const axios = Axios.create({
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "X-Version": version,
  },
  withCredentials: true,
});

export const fetcher = async (url: string) => {
  return axios.get(url).then((res) => {
    /*
    if (url.startsWith("/api/v1/news")) {
      console.log("axios", res.data);
    }
    */
    return res.data;
  });
};

export function undefineKeyToNull(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      obj[key] = null;
    }
  });

  return obj;
}
