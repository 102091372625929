<template>
  <div id="session-sign-in">
    <LoginForm @login="login">
      <MessageList :messages="errors" status="error" />
    </LoginForm>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useAuthorize, useRouterUtil } from "/@/vue/composables";
import { MessageList } from "/@/vue/components/Atom";
import { LoginForm } from "/@/vue/components/Organisms";
import { User } from "/@/types";

// login

const { login: userLogin } = useAuthorize();
const { goto } = useRouterUtil();

const loading = ref<boolean>(false);
const errors = ref<Array<string>>([]);

async function login(signinForm: User) {
  loading.value = true;

  if (await userLogin(signinForm)) {
    goto({ name: "UserIndex" });
  } else {
    errors.value = [
      "ログインに失敗しました。IDとパスワードを確認してください。",
    ];
  }
  loading.value = false;
}
</script>
