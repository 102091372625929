import { useRouter } from "vue-router";
import { useStore } from "/@/vue/store";
import { User, SingUpResponse } from "/@/types";

export function useAuthorize() {
  const store = useStore();
  const router = useRouter();

  async function login(user: User) {
    return await store.dispatch("user/LOGIN", user);
  }

  async function logout() {
    await store.dispatch("user/LOGOUT");
    router.push({ name: "Index" });
  }

  async function signup(user: User): Promise<SingUpResponse> {
    return await store.dispatch("user/SIGN_UP", user);
  }

  return {
    login,
    logout,
    signup,
  };
}
