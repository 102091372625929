import {
  ComputedRef,
  Ref,
  UnwrapNestedRefs,
  computed,
  reactive,
  ref,
} from "vue";
import Zod from "zod";
import { getZodErrors } from "/@/modules/zodUtils";
import { ZodFormattedErrors } from "/@/types/zod";

export function useZodScheme() {
  type FormAndError<T> = {
    form: UnwrapNestedRefs<T>;
    errors: ComputedRef<ZodFormattedErrors>;
    startValidation: Ref<boolean | undefined>;
    targetScheme: Ref<Zod.ZodSchema>;
  };

  function useFormAndErrors<T extends {}>(
    scheme: Zod.ZodSchema,
    initialValues: T = {} as T
  ): FormAndError<T> {
    const targetScheme = ref(scheme);
    const startValidation = ref<boolean>();
    // @ts-ignore
    const form = reactive<T>(initialValues);
    const errors = computed<ZodFormattedErrors>(() => {
      return getZodErrors(targetScheme.value, form, startValidation.value);
    });

    return {
      form,
      errors,
      startValidation,
      targetScheme,
    };
  }

  return {
    useFormAndErrors,
  };
}
