<template>
  <div>
    <div v-if="selectedItems.length" class="table-action-menu-button-wrapper">
      <MenuButton :menu-items="menuItems" />
    </div>
    <DataTable
      v-model:selection="selectedItems"
      striped-rows
      paginator
      :rows="10"
      :value="news"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="single"
      @row-click="$emit('selectNews', $event.data.id)"
    >
      <Column
        v-if="!isMobile"
        selection-mode="multiple"
        header-style="width: 3rem"
      >
      </Column>
      <Column
        sortable
        field="title"
        header="タイトル"
        :style="isMobile ? {} : { width: '200px', 'min-width': '200px' }"
      >
        <template #body="{ data }">
          <span>{{ newsToTitle(data) }}</span>
        </template>
      </Column>
      <Column
        sortable
        field="publishedAt"
        header="公開日時"
        :style="
          isMobile
            ? { width: '150px', 'min-width': '150px' }
            : { width: '150px', 'min-width': '150px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.publishedAt, "slashStyle") }}</span>
        </template>
      </Column>
      <Column
        v-if="!isMobile"
        filed="content"
        header="本文"
        style="min-width: 150px"
      >
        <template #body="{ data }">
          <i v-if="data.attachedPdfs.length" class="pi pi-paperclip me-2"></i>
          <span>{{ stripHtml(data.content.slice(0, 50)) }}</span>
        </template>
      </Column>
      <Column
        :style="
          trashed
            ? { width: '90px', 'min-width': '90px' }
            : { width: '60px', 'min-width': '60px' }
        "
      >
        <template #body="{ data }">
          <div class="d-flex">
            <BasicButton
              v-if="trashed"
              variant="primary"
              icon="pi pi-refresh"
              need-icon
              text
              button-type="submit"
              class="me-2"
              @click="$emit('restoreNews', data.id)"
            />
            <BasicButton
              variant="danger"
              icon="pi pi-trash"
              need-icon
              text
              button-type="submit"
              @click="$emit('trashNews', data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { newsToTitle } from "/@/modules/news";
import { basicFormatter } from "/@/modules/luxon";
import { stripHtml } from "/@/modules/string";
import { useMqUtils } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules";
import { NewsClient } from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { MenuItem } from "primevue/menuitem";

const props = defineProps<{
  news: NewsClient[];
  trashed?: boolean;
}>();

const emit = defineEmits<{
  (e: "selectNews", id: number): void;
  (e: "trashNews", id: number | number[]): void;
  (e: "restoreNews", id: number | number[]): void;
}>();

const { isMobile } = useMqUtils();

const selectedItems = ref<NewsClient[]>([]);

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "復元する",
      icon: "pi pi-refresh",
      visible: props.trashed,
      command: () => {
        emit(
          "restoreNews",
          selectedItems.value.map((n) => n.id)
        );
        selectedItems.value = [];
      },
    },
    {
      label: props.trashed ? "完全に削除する" : "ゴミ箱へ移動",
      icon: "pi pi-trash",
      command: () => {
        emit(
          "trashNews",
          selectedItems.value.map((n) => n.id)
        );
        selectedItems.value = [];
      },
    },
  ];
});
</script>

<style lang="scss">
.table-action-menu-button-wrapper {
  position: relative;

  button {
    position: absolute;
    top: -35px;
    left: 0;
  }
}
</style>
