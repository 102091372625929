<template>
  <div id="user">
    <UserHeader :user="user" class="mb-2" />
    <router-view id="user-root" />
  </div>
</template>

<script setup lang="ts">
import { useUser } from "/@/vue/composables";
import { UserHeader } from "/@/vue/components/Layouts";

const { user } = useUser();
</script>

<style lang="scss" scoped>
.user-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
</style>
