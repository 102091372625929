<template>
  <div>
    <div class="file-upload-component">
      <FileUpload
        name="demo[]"
        url="/api/upload"
        :multiple="true"
        :file-limit="1"
        accept=".pdf"
        :max-file-size="5000000"
        :show-upload-button="false"
        :show-cancel-button="false"
        invalid-file-size-message="ファイルのサイズが大きすぎます"
        invalid-file-limit-message="添付できるファイルは1つのみです"
        invalid-file-type-message="ファイルの形式が正しくありません。添付可能なファイル形式はPDFのみです。"
        choose-label="ファイル選択"
        @select="selectFile"
        @remove="removeFile"
      >
        <template #empty>
          <p class="text-danger">
            PDFを1つのみ添付可能です。ファイル1つの最大サイズは5MBです。すでに添付書類が存在する状態で、新しい添付書類を設定した場合、更新時に過去の添付書類は削除されます。
          </p>
        </template>
      </FileUpload>
    </div>
  </div>
</template>

<script setup lang="ts">
import FileUpload from "primevue/fileupload";

defineProps<{
  modelValue?: string[];
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value?: string[]): void;
}>();

async function selectFile(e: any) {
  const files = e.files;
  const reader = new FileReader();

  const results: string[] = await Promise.all(
    files.map(async (file: any) => {
      let blob = new Blob([file], { type: file.type });

      reader.readAsDataURL(blob);

      return new Promise(
        (resolve) => (reader.onload = () => resolve(reader.result))
      );
    })
  );

  emit("update:modelValue", results);
}

function removeFile() {
  emit("update:modelValue", []);
}
</script>

<style lang="scss">
.file-upload-component {
  /*
  .p-fileupload-buttonbar {
    .p-button {
      background-color: $main-light-green;
      color: black;
      border-color: $main-light-green;
    }
  }
  */

  .p-fileupload-file-thumbnail,
  .p-fileupload-file-badge {
    display: none;
  }
}
</style>
