<template>
  <div>
    <BasicDatepicker
      ref="datepicker"
      :input="isoDate"
      :auto-apply="autoApply"
      :min-date="minDate?.toJSDate()"
      :max-date="maxDate?.toJSDate()"
      :start-date="startDate?.toJSDate()"
      :input-class-name="`${isPc ? '' : 'mobile-ui'}`"
      :state="isValid ? undefined : false"
      :multi-dates="multiDates"
      :range="range"
      :disabled-dates="disabledDates"
      :allowed-dates="allowedDates"
      :markers="markers"
      :inline="inline"
      :slash-style="slashStyle"
      :without-border="withoutBorder"
      :teleport-center="teleportCenter"
      @update:input="update"
      @update-month-year="handleUpdateMonthYear"
    />
  </div>
</template>

<script setup lang="ts">
import { DateTime } from "luxon";
import { computed, ref } from "vue";
import { fromDate, fromISO, luxonNow } from "/@/modules/luxon";
import { BasicDatepicker } from "/@/vue/components/Atom";
import { PickerTimeObject, Markers } from "/@/types/datepicker";

const props = withDefaults(
  defineProps<{
    value?: string | string[];
    isPc?: boolean;
    minDate?: DateTime;
    maxDate?: DateTime;
    startDate?: DateTime;
    isValid?: boolean;
    multiDates?: boolean;
    range?: boolean;
    autoApply?: boolean;
    inline?: boolean;
    disabledDates?: string[];
    allowedDates?: string[];
    markers?: Markers[];

    slashStyle?: boolean;
    withoutBorder?: boolean;
    teleportCenter?: boolean;
  }>(),
  {
    value: undefined,
    today: () => luxonNow(),
    minDate: undefined,
    maxDate: undefined,
    startDate: undefined,
    autoApply: true,
    disabledDates: undefined,
    allowedDates: undefined,
    markers: undefined,
  }
);

const emit = defineEmits(["update:value", "updateMonthYear"]);

const isoDate = computed<string | string[] | undefined>(() => {
  if (!props.value) {
    return undefined;
  }

  if (props.value instanceof Array) {
    return props.value
      .map((v) => fromISO(v)?.toISO())
      .filter((item): item is string => item !== undefined);
  } else {
    return fromISO(props.value)?.toISO();
  }
});

function update(v: Date | Date[] | PickerTimeObject | undefined) {
  if (!v) {
    emit("update:value", undefined);
    return;
  }
  if (v instanceof Date) {
    emit("update:value", fromDate(v).toISO());
  } else if (v instanceof Array) {
    emit(
      "update:value",
      v.map((d) => fromDate(d).toISO())
    );
  } else if (v.hours) {
    emit("update:value", v);
  }
}

function handleUpdateMonthYear(prms: { month: number; year: number }) {
  const { month, year } = prms;

  emit("updateMonthYear", { month, year });
}

// component methods

const datepicker = ref();

function openMenu() {
  datepicker.value?.openMenu();
}

defineExpose({
  openMenu,
});
</script>

<style lang="scss" scoped></style>
